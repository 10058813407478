import style from "./style.module.css";
import useApi from "../../components/Loading/LoadingApi";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/Layout/BreadCrumb";
import { h_line, s_line } from "../../assets";
import { isMobileApp } from "../../utils/functions";
export default function SellWithUsAr() {
  useApi(600);
  const { i18n } = useTranslation();
  return (
    <div
      className={style.Content}
      style={{
        direction: i18n.language === "en" ? "rtl" : "rtl",
        textAlign: i18n.language === "en" ? "justify" : "justify",
      }}
    >
      {!isMobileApp &&<img className={style.image_h_line} src={h_line} alt="h_line" />}
      {!isMobileApp && <BreadCrumb />}
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>البيع مع صناع </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          يُقصد بشروط الأحكام والشروط التي يتم بموجبها توريد وتسليم المشتريات
          إليك كمشترٍ على موقع{" "}
          <a style={{ fontFamily: "sans" }} href="https://sona3.ae/">
            sona3.ae
          </a>{" "}
          أو على تطبيق الهاتف المتحرك الخاص بنا (المشار إليهما مجتمعين
          بـ"الموقع") الذي تمتلكه (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) في
          دولة الإمارات العربية المتحدة بموجب ترخيص رقم CN-4899615 لدى دائرة
          التنمية الاقتصادية بأبوظبي .{" "}
        </p>
        <p dir="rtl">
          تسري هذه الشروط والأحكام، التي تسمى فيما يلي (الشروط)، على ما تقوم
          (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) ويشار إليها بـ (صناع مجمع
          للتعامل الإلكتروني) من بيع وتوريد لجميع المنتجات والأجهزة وعلى ما
          تؤديه من جميع الخدمات للعميل.
        </p>
        <p dir="rtl">
          هذه الشروط والأحكام العامة ("الشروط") التي يتم إبرامها بينك وبين (صناع
          مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و)
        </p>

        <p dir="rtl">
          فيما يخص استخدامك لهذا الموقع، ودخولك اليه، واستخدامك لخدمات الموبايل
          الخاصة بـ (صناع مجمع للتعامل الإلكتروني) ، وخدمات وحدة الطلب الهاتفي
          والرد الصوتي لدى (صناع مجمع للتعامل الإلكتروني)، والتطبيقات البرمجية
          الخاصة بموقع (صناع مجمع للتعامل الإلكتروني التي تم تنزيلها على سطح
          المكتب الخاص بك، وعناوين البريد الإلكتروني الخاصة بموقع (صناع مجمع
          للتعامل الإلكتروني (، والمواقع والخدمات الأخرى التي تم نشر هذه الشروط
          عليها أو الإشارة إليها ("خدمات صناع مجمع للتعامل الإلكتروني") والوعود
          والالتزامات المنصوص عليها هنا، التي بدورك تعتزم الالتزام بها قانونًا،
          توافق أنت و (صناع مجمع للتعامل الإلكتروني (على ما يلي:
        </p>
        <ul dir="rtl">
          <li>
            <strong>1. </strong>يخضع وصولك لخدمات صناع واستخدامها لهذه الشروط،
            والتي تتضمن بيان سياسة الخصوصية والمنشورات أو السياسات الأخرى
            المنفصلة، بالإضافة إلى أي تعديلات تضيفها شركة (صناع مجمع للتعامل
            الإلكتروني (وبالإضافة الى جميع القوانين واللوائح المعمول بها.
          </li>
          <li>
            <strong>2. </strong>إذا كانت أي من خدمات صُناع قد حددت شروط وأحكام
            استخدام أو بيان خصوصية أو سياسة أخرى منفصلة ("شروط منفصلة")، فإن هذه
            الشروط المنفصلة التي ممكن أن يتم تعديلها من وقت لآخر يجب أن تُطبّق
            في أي إجراء يتعلق بأستخدامك لتلك الخدمات المنصوصة من قبل صناع. وفي
            حال تواجد أي تعارض مباشر بين الشروط المنفصلة وهذه الشروط، فإن الشروط
            المنفصلة تحكُم.
          </li>
          <li>
            <strong>3. </strong>من خلال استخدامك لخدمات صناع ، أنت توافق على
            الالتزام بهذه الشروط وبأي سياسات أخرى قابلة للتطبيق. إذا كنت لا ترغب
            في الالتزام بهذه الشروط أو السياسات، فلا تستخدم خدمات موقع صناع .
          </li>
          <li>
            <strong>4. </strong>توفر خدمات صناع معلومات متعلقة بمختلف المنتجات
            والخدمات، بالإضافة الى فرصة الحصول على معلومات إضافية بخصوص تلك
            المنتجات والخدمات وفرصة شرائها. هذه الشروط والمعلومات التي تقدمها
            خدمات موقع صناع لا تتجاوز بأي حال من الأحوال شروط وأحكام الشراء لأي
            منتج أو خدمة باستثناء ما هو محدد هنا. وفي حال وجود أي قسم داخل
            الموقع أو أي ميزة يقدمها موقع وخدمات صناع يتضمّن بنود وشروط محددة
            فيما يتعلق باستخدامها ("شروط محددة")، فإن هذه الشروط المحددة تُضاف
            إلى هذه الشروط ويتم تطبيقها. وفي حال تواجد أي تعارض مباشر بين هذه
            الشروط والشروط المحددة، فإن الشروط المحددة تحكُم .
          </li>
          <li>
            <strong>5. </strong>إن عرض أعمالك ومنتجاتك على موقع صناع يعني بأن
            العالم بأكمله سيراها. عليك أن تكون على علم بأن نشر عملك على موقعنا
            سيجذب اليك بعض المسؤوليات القانونية. يجب أن تحرص على التأكد من أنك
            مدرك لكل هذه المسؤوليات وأن تجعل نفسك على علم بمتطلبات هذه الوثيقة,
            من المهم أن تقرأ وتفهم الاتفاقية أدناه. يعني استخدامك لهذا الموقع
            الإلكتروني قبولك لهذه الاتفاقية وأي تغييرات يتم إجراؤها بعد بدء
            الاستخدام.
          </li>
        </ul>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Changes in Terms" />
        <h1 className={style.h1}> التغييرات في الشروط والأحكام </h1>
        <img className={style.s_line} src={s_line} alt="Changes in Terms" />
      </div>
      <div>
        <p dir="rtl">
          <strong>- </strong> يحق لموقع صناع في أي وقت وبدون إشعار مسبق، بناءً
          على تقديره الخاص، مراجعة هذه الشروط أو فرض شروط وأحكام جديدة فيما
          يتعلق بالوصول إلى أو استخدام خدمات موقع صناع. تسري هذه المراجعات
          والإضافات فورًا على الإشعار بذلك، والتي يمكن تقديمها بأي وسيلة، بما في
          ذلك على سبيل المثال، لا الحصر، نشر الشروط والأحكام المعدلة أو الإضافية
          على خدمات صُناع.
        </p>

        <p dir="rtl">
          <strong>- </strong> أنت مسؤول عن مراجعة هذه الشروط بشكل دوري، لأي
          تعديل على هذه الشروط قد يؤثر على حقوقك أو التزاماتك بموجب هذه
          الاتفاقية.
        </p>

        <p dir="rtl">
          <strong>‌- </strong> أنت توافق على أنك سوف تُعتبر على علم بأي تعديل من
          قبل صُناع لهذه الشروط. أي وصول أو استخدام لخدمات صُناع من قبلك بعد
          التعديلات أو الإضافات على هذه الشروط سوف يتم اعتباره موافقتك على هذه
          التعديلات أو الإضافات.
        </p>
        <p dir="rtl">
          <strong>‌- </strong> أنت توافق على الامتثال لجميع القوانين والأنظمة
          والمراسيم واللوائح المعمول بها فيما يخص استخدامك لخدمات موقع صُناع
          وشرائك للمنتجات أو الخدمات من خلال تلك القوانين لن يكون أي تعديل على
          هذه الشروط من قبل أي طرف آخر غير صُناع صالحًا أو قابلًا للتنفيذ ضد
          صُناع ما لم يتم الاتفاق عليه صراحة من قبل صُناع في كتابة موقعة من قبل
          مسؤول مخوّل حسب الأصول من صُناع.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>إغلاق الحساب</h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          <strong>أ‌- </strong> تسري هذه الشروط حتى يتم إنهاؤها من قبل صُناع. قد
          تقوم صُناع بإنهاء هذه الشروط دون سابق إنذار وفي أي وقت فيما يتعلق بأي
          من خدمات موقع صناع.
        </p>
        <p dir="rtl">
          <strong>ب- </strong> في حالة الإنهاء، لن يكون الوصول إلى خدمات صناع
          مُصرحًا لك، أما القيود المفروضة عليك فيما يتعلق بالمحتوى، وإخلاء
          المسئولية، والتعويضات، وحدود الالتزامات المنصوص عليها في هذه الشروط
          سوف تُطبق.
        </p>
        <p dir="rtl">
          <strong>ت- </strong> كما سيكون لدى صُناع الحق أيضًا في أي وقت وبدون أي
          اشعار مسبق بإنهاء بعض أو كل خدمات موقع صُناع أو أي ميزة أو جزء منها،
          أو أي منتجات أو خدمات يتم تقديمها من خلالهم، أو إنهاء حق أي شخص في
          الوصول إلى خدمات موقع صُناع أو استخدامها أو الوصول الى أي ميزة أو جزء
          منها واستخدامها.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>أهلية المستخدم</h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          <strong>ا- </strong> يقر المستخدم بأنه ذا أهلية قانونية معتبرة شرعاً
          ونظاماً للتعامل مع المتجر، أو أن عمره لا يقل عن ثمانية عشرة عاماً.
        </p>

        <p dir="rtl">
          <strong>ب- </strong> يوافق المستخدم بأنه في حال مخالفته لهذه المادة،
          فإنه يتحمّل تبعات هذه المخالفة أمام الغير.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>ادراج المنتجات والبيع</h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          تحتفظ صناع بالحق في تعديل أو تغيير مكان عرض المنتج أو الخدمة داخل
          التطبيق أو الموقع الإلكتروني إذا كانت الأمور الفنية تتطلب هذا التغيير
          أو التعديل.
        </p>
        <p dir="rtl">
          توفر (صناع مجمع للتعامل الإلكتروني - ذ.م.م-ش.ش.و ) منصة للمتسوقين
          والباعة المستقلين لشراء وبيع السلع. يجوز للبائعين ادراج المنتجات
          وإجراء معاملات البيع للعناصر والسلع التي يمكنهم بيعها قانونياً. ستتم
          إزالة أي عنصر لا يستوفي هذا المعيار وفقًا لتقدير صناع وسيتم تقييم حساب
          المستخدم لاحتمالية الإنهاء. تحتفظ صناع بالحق في مراجعة أو تقييد أو
          تعليق أو إنهاء حساب المستخدم في أي وقت دون تفسير أو إشعار. تحتفظ صناع
          بالحق في إزالة المحتوى الذي يعتبر غير لائق أو ضار أو غير قانوني.
        </p>
        <p dir="rtl">
          عن طريق الوصول إلى الموقع واستخدامه، يضمن البائعين أن يتم وصف المنتجات
          التي تُعرض للبيع بدقة كاملة.
        </p>
        <p dir="rtl">
          تعتبر المعاملة بين المشتري والبائع عقدًا ملزمًا قانونيًا بين الطرفين.
        </p>
        <p dir="rtl">
          يجب أن يضمن البائع حالة العناصر او السلع عند بيعها ولا يجوز له أن يحرف
          العنصر المراد بيعه بأي شكل من الأشكال أو أن يسيء وصفه. يحظر على
          المستخدمين مشاركة الحسابات أو استخدام حساب مستخدم آخر بدون إذن. سيؤدي
          انتهاك هذه السياسة إلى إجراء تحقيق من قِبل صناع ، وربما يؤدي إلى إنهاء
          حساب المستخدم أو الإجراءات التصحيحية الأخرى التي تعتبر مناسبة من قبل{" "}
          <a style={{ fontFamily: "sans" }} href="https://sona3.ae/">
            sona3.ae
          </a>{" "}
          مع الوضع في الإعتبار أحقية صناع في إتخاذ الإجراءات القانونية حيال ذلك
          .
        </p>
        <p dir="rtl">
          باستخدامك منصة صناع فإنك توافق على أن (صناع مجمع للتعامل
          الإلكتروني-ذ.م.م-ش.ش.و) ليست مسؤولة عن تحديد الضرائب المطبقة على عملك
          وأنك ستضمن تحويل جميع الضرائب المناسبة إلى السلطات الضريبية المعنية.
        </p>
        <p dir="rtl">
          يجب أن يكون لدى مورد الخدمة أو السلعة أو البائع رخصة تجارية سارية
          المفعول خاصة به ، ويلتزم مورد الخدمة أو السلعة أو البائع بتجديد الرخصة
          التجارية بشكل دوري عند انتهاء ترخيصها على منصة صناع ، ويوافق مورد
          الخدمة أو السلعة أو البائع على أنه في حالة انتهاكه لهذا البند، فإنه
          سيتحمل عواقب هذه المخالفة أمام الغير وأنه مسؤول بالكامل عن عواقب عدم
          تجديد الرخصة التجارية.
          {/* يجب أن يكون لدى مورد أو بائع الخدمات أو السلع ترخيص تجاري ساري المفعول خاص به، ويلتزم مورد أو بائع الخدمات أو السلع بتجديد الترخيص التجاري بشكل دوري عند انتهاء ترخيصه على منصة صُناع ويوافق مورد أو بائع الخدمات أو السلع على أنه في حال مخالفته لهذا البند فإنه يتحمل عواقب هذه المخالفة قبل الغير وأنه مسؤول مسؤولية كاملة عن عواقب عدم تجديد الترخيص التجاري. */}
        </p>
      </div>
      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Payment Services in SONA3"
        />
        <h1 className={style.h1}>خدمات الدفع والسداد في صناع</h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Payment Services in SONA3"
        />
      </div>
      <div>
        <p dir="rtl">
          <strong>
            يوفّر ( صناع مجمع للتعامل الإلكتروني - ذ.م.م-ش.ش.و ) عبر شركائه نظام
            الدفع والسداد في ( صناع مجمع للتعامل الإلكتروني - ذ.م.م-ش.ش.و )فيمكن
            أن يتم عبر الانترنت كليا من خلال خيارات الدفع المتوفرة على (صناع
            مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و)أو من خلال أي طريقة دفع يوفرها
            (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) من حين لآخر, علي سبيل
            المثال :
          </strong>
        </p>

        <p dir="rtl">
          <strong>ا-</strong> من خلال بطاقة ائتمانية أو بطاقة خصم
        </p>

        <p dir="rtl">
          <strong>ب-</strong> من خلال محفظتك الإلكترونية
        </p>

        <p dir="rtl">
          <strong>ت-</strong> نقدا عن التسليم ( مبلغ لا يتجاوز 2500 درهم إماراتي
          )
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>العناصر والسلع المحظورة</h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          <strong>- </strong> المواد الكيميائية الخطرة
        </p>
        <p dir="rtl">
          <strong>- </strong> المواد المقرصنة.
        </p>
        <p dir="rtl">
          <strong>- </strong> السلع المقلدة، او الماركات.
        </p>
        <p dir="rtl">
          <strong>- </strong> البيانات الشخصية أو المعلومات.
        </p>
        <p dir="rtl">
          <strong>- </strong> العناصر أو المنتجات التي تحمل أو تنقل رمز أو
          إشارات مخفية بقصد التسبب في ضرر أو تتبع معلومات شخصية أو معاملات من أي
          نوع.
        </p>
        <p dir="rtl">
          <strong>- </strong> عناصر أو منتجات غير موصوفة او معروضة بشكل صحيح.
        </p>
        <p dir="rtl">
          <strong>- </strong> مواد ضارة أو مضايقة أو ترويعية أو تشهيرية.
        </p>
        <p dir="rtl">
          <strong>- </strong> المواد أو المنتجات أو المواد الخطرة.
        </p>
        <p dir="rtl">
          <strong>- </strong> البغاء أو خدمات أخرى مماثلة.
        </p>
        <p dir="rtl">
          <strong>- </strong> العناصر التي تنتهك شروط الخدمة أو شروط الاستخدام
        </p>
        <p dir="rtl">
          <strong>- </strong> أي عنصر أو خدمة تنتهك القانون المحلي أو الولائي أو
          الفيدرالي أو الدولي المعمول به فيما يتعلق بتصنيع أو توزيع أو الإعلان
          أو نقل المادة المذكورة
        </p>
        <p dir="rtl">
          <strong>- </strong> الاشياء المسروقة
        </p>
        <p dir="rtl">
          <strong>- </strong> الالعاب النارية
        </p>
        <p dir="rtl">
          <strong>- </strong> مواد للبالغين/ مواد إباحية غير مناسبة للأطفال دون
          سن 18.
        </p>
        <p dir="rtl">
          <strong>- </strong> المخدرات
        </p>
        <p dir="rtl">
          <strong>- </strong> الكحول
        </p>
        <p dir="rtl">
          <strong>- </strong> التبغ
        </p>
        <p dir="rtl">
          <strong>- </strong> الالكترونيات المنتجة الشامل
        </p>
        <p dir="rtl">
          <strong>- </strong> الآثار المحمية
        </p>
        <p dir="rtl">
          <strong>-</strong> العقارات
        </p>
        <p dir="rtl">
          <strong>- </strong> المنتجات العاجية أو الحيوانية من الأنواع المهددة
          بالانقراض التي يتم جمعها بطريقة غير مشروعة
        </p>
        <p dir="rtl">
          <strong>- </strong> السيارات ، أو أجزاء للسيارات أو المركبات الترفيهية
        </p>
        <p dir="rtl">
          <strong>- </strong> العناصر التي ليست في حيازتك أو العناصر التي لا
          تنوي تسليمها
        </p>
        <p dir="rtl">
          <strong>- </strong> سلع غير قانونية أو مقلدة أو مهربة من أي نوع.
        </p>
        <p dir="rtl">
          المواد أو المنتجات المحمية بحقوق الطبع والنشر أو العلامات التجارية أو
          المسجلة ببراءة اختراع من قبل طرف آخر.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>سياسات المتاجر المستقلة</h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          نشجع جميع المستخدمين الذين يختارون فتح متجر على موقع صناع على إنشاء
          ونشر قائمة بالمتاجر وسياسات الشراء التي توضح التفاصيل المحددة لمتجرهم.
          تتضمن هذه السياسات، على سبيل المثال لا الحصر: معالجات الدفع المقبولة،
          وسياسة الاستبدال والإرجاع، وسياسات المبيعات. يجب أن تتوافق جميع سياسات
          المتجر مع سياسات الاستخدام المقبول والخصوصية المنصوص عليها في{" "}
          <a style={{ fontFamily: "sans" }} href="https://sona3.ae/">
            sona3.ae
          </a>
          . في حالة تعارض سياسات المتجر مع شروط استخدام{" "}
          <a style={{ fontFamily: "sans" }} href="https://sona3.ae/">
            sona3.ae
          </a>
          ، تحل شروط الاستخدام محل أي سياسات أخرى للمتجر.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          المدفوعات
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="rtl">
          - سيتم تسوية ومعالجة المدفوعات للتجار وتحويلها الي حسابهم البنكي شهريا
          لمراعاة مرور 30 يوم من اخر يوم بفترة التسوية :{" "}
        </p>
        <p>1. فترة التسوية: من تاريخ 1 الي أخر يوم بالشهر</p>
        <p>
          2.مثال: سيتم تحويل المدفوعات للمبيعات التي تمت في الفترة من 1 يوليو
          إلى 31 يوليو في الأول من سبتمبر.
        </p>
        <p>- تشمل الدفعات الطلبات التي تم شحنها خلال فترة التسوية المحددة.</p>
        <p>
          - يوافق البائع علي خصم رسوم عمولة (صناع مجمع للتعامل
          الإلكتروني-ذ.م.م-ش.ش.و) من مبيعات السلعة أو الخدمة أو المنتج المذكورة
          في المنصة الإلكترونية في حال تم ذكر هذه العمولة علي منصة صناع ويحق لـ
          منصة صناع في أي وقت الحصول علي رسوم اشتراك مقابل تسويق الخدمات أو
          المنتجات أو السلع في حال تم ذكر هذه الرسوم علي منصة صناع.
        </p>
        {/* <p>
        - يحق لمنصة صُناع - في أي وقت - تحصيل رسوم الاشتراك مقابل خدمات التسويق أو المنتجات أو البضائع عند إعلان هذه الرسوم على منصة صُناع.
        </p> */}
      </div>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 className={style.h1}>
          الإشعار والإجراءات الخاصة بمطالبات انتهاك حقوق الملكية الفكرية
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <strong>
          إذا كنت تعتقد أن عملك قد تم نسخه بطريقة تشكل انتهاكاً لحقوق النشر أو
          أن حقوق الملكية الفكرية الخاصة بك قد تم انتهاكها بأي طريقة، فيرجى
          تزويد وكيل حقوق النشر في موقع صناع بالمعلومات الكتابية المحددة أدناه:
        </strong>
        <ul dir="rtl">
          <li>
            توقيع إلكتروني أو مادي للشخص المخول بالتصرف نيابة عن مالك حقوق النشر
            أو غيرها من حقوق الملكية الفكرية{" "}
          </li>
          <li>وصف لمكان وجود المادة التي تدعي انتهاكها على موقع صناع </li>
          <li>عنوانك ورقم هاتفك ورقم الفاكس وعنوان البريد الإلكتروني</li>
          <li>
            بيان منك بأنك تعتقد بحسن نية أن الاستخدام المتنازع عليه غير مصرح به
            من قبل مالك حقوق النشر أو الملكية الفكرية أو وكيله أو القانون.
          </li>
          <li>
            إقرار منك، تحت طائلة عقوبة الحنث باليمين، بأن المعلومات الواردة
            أعلاه في إشعارك دقيقة وأنك صاحب حقوق الطبع والنشر أو صاحب الملكية
            الفكرية أو أنك مخول بالتصرف نيابةً عن مالك حقوق الطبع والنشر أو أي
            مالك آخر للملكية الفكرية
          </li>
        </ul>
        <p dir="rtl">
          يمكن الوصول إلى وكيل حقوق نشر صناع لإشعاره بمطالبات حقوق الطبع والنشر
          أو أي انتهاك آخر لحقوق الملكية الفكرية من خلال مراسلته على{" "}
          <a
            href="mailto:info@sona3.ae"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            info@sona3.ae
          </a>
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Prices and Orders" />
        <h1 className={style.h1}>الأسعار، الطلبات</h1>
        <img className={style.s_line} src={s_line} alt="Prices and Orders" />
      </div>
      <div>
        <p dir="rtl">
          <strong>- </strong>سيقوم موقع صُناع بإضافة رسوم الشحن والمناولة وضريبة
          المبيعات/الاستخدام المطبقة فوق السعر الذي يدفعه المشتري.
        </p>
      </div>

      <div>
        <p dir="rtl">
          <strong>- </strong>يتم احتساب جميع رسوم الشحن لدينا على أساس الوزن
          الحجمي للمنتج. الوزن الحجمي للمنتج هو: العرض × الطول × الارتفاع
          بالسنتيمترات/5000 = الوزن الحجمي بالكيلوغرام.
        </p>
      </div>

      <div>
        <p dir="rtl">
          {" "}
          <strong>- </strong>يحتفظ موقع صُناع بالحق دون إشعار مسبق بخصم المبلغ
          المتبقي من رسوم الشحن من مورد الخدمة أو السلعة في حال اكتشاف موقع
          صُناع في اي وقت ان الوزن الحجمي للمنتج الذي ذكره مورد الخدمه او السلعة
          علي موقع صُناع غير مطابق للوزن الحجمي الحقيقي للمنتج عند توصيله
          للمشتري، لذلك يجب علي مورد الخدمة أو السلعة ان يذكر الوزن الحجمي
          الحقيقي للمنتج علي موقع صُناع.
        </p>
      </div>

      <div>
        <p dir="rtl">
          <strong>- </strong>لا يشكل إيصال تأكيد الطلب الخاص بك موافقة موقع
          صُناع على الطلب. قبل قبول الموقع للطلب، قد يتطلب الأمر التحقق من
          المعلومات والموافقة على الطلب. يحتفظ موقع صُناع بالحق في أي وقت بعد
          استلام طلبك بقبول أو رفض طلبك، أو أي جزء منه، حتى بعد استلامك لرسالة
          تأكيد الطلب من موقع صُناع ، لأي سبب من الأسباب. يحتفظ موقع صُناع بالحق
          في الحد من كمية الطلب على أي منتج ورفض الخدمة لأي عميل دون إشعار مسبق.
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>- </strong>في حالة إدراج سعر غير صحيح لمنتجٍ أو خدمةٍ ما بسبب
          معلومات خاطئة من البائعين أو خطأ مطبعي، يحق لموقع صُناع رفض أو إلغاء
          الطلبات الموضوعة للمنتج أو الخدمة المُدرجة بالسعر غير الصحيح، بغض
          النظر عما إذا كان الطلب تم تاكيده.
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>- </strong>إن مخاطر الخسارة لجميع المنتجات التي تشتريها ويتم
          شحنها من قبل موقع صُناع تنتقل اليك عند تسليم موقع صُناع المنتج لشركة
          الشحن لتوصيله اليك.
        </p>
      </div>

      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Cancelling The Orders"
        />
        <h1 className={style.h1}>إلغاء الطلب</h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Cancelling The Orders"
        />
      </div>
      <div>
        <p dir="rtl">
          <strong>
            1-يمكن إلغاء طلب العميل واسترداد المبلغ المدفوع في حالة حدوث ما يلي
            :
          </strong>
        </p>
      </div>

      <div>
        <ul dir="rtl">
          <li>
            <strong>1. </strong>إذا لم يقبل البائع الطلب، وإلا إذا قبل البائع
            الطلب، فإن سياسة الإلغاء غير صالحة .
          </li>
          <li>
            <strong>2. </strong>إذا قام البائع بإلغاء الطلب .
          </li>
          <li>
            <strong>3. </strong>في حالة حدوث أي مشكلة فنية مع التطبيق .
          </li>
          <li>
            <strong>4. </strong>صناع لها الحق أن يقرر إلغاء طلب أي عميل إذا كان
            هناك شك في أن حساب العميل مزيف .
          </li>
        </ul>
      </div>
      <div>
        <p dir="rtl">
          <strong>
            ب- سيتم إرجاع المبالغ المستردة إلى طريقة الدفع التي استخدمها العميل
            في البداية.{" "}
          </strong>
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>
            ج- يمكنك إلغاء الطلب واسترداد كامل المبلغ حتى يتم قبول الطلب من قبل
            البائع. إذا كنت بحاجة إلى إلغاء الطلب بعد قبوله من قبل البائع، فيرجى
            التواصل معنا{" "}
          </strong>
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>
            د- صناع لها الحق في عدم إصدار مبالغ مستردة كاملة أو جزئية إذا قمت
            بإلغاء الطلب بعد قبوله{" "}
          </strong>
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>
            ه- لها الحق في إلغاء أي طلب، قبل القبول أو بعده، وسوف يتم إخطارك على
            الفور بأي إلغاء من هذا القبيل.
          </strong>
        </p>
      </div>
      <div>
        <p dir="rtl">
          <strong>
            و-العملاء المؤهلون لسياسة الإلغاء، تستغرق عملية استرداد الأموال ما
            يصل إلى 14 إلى 21 يوم عمل. يرجى الاتصال بنا على (
            <a
              href="tel:+971600505006"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "sans-serif",
                direction: "ltr",
              }}
            >
              ٦٠٠٥ ٠٥٠ ٠٦ ١٧٩+
            </a>
            )، وسيقوم فريق دعم عملاء صُناع بالتحقق من طلب الإلغاء وبدء عملية
            الإلغاء
          </strong>
        </p>
      </div>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Disclaimer" />
        <h1 className={style.h1}>
          إخلاء المسؤولية المتعلقة بموقع صناع والمحتوى الموجود فيه
        </h1>
        <img className={style.s_line} src={s_line} alt="Disclaimer" />
      </div>
      <div>
        <p dir="rtl">
          لا يقدم موقع صُناع أي ضمانات أو إقرارات على الإطلاق فيما يتعلق بخدمات
          الموقع أو أي موقع أو محتوى مرتبط به، بما في ذلك توافر أي من المواقع أو
          المحتوى أو المعلومات والمواد المتعلقة به أو دقتها أو اكتمالها أو
          ملائمتها في ذلك المحتوى، والمعلومات والمواد. كما لا يضمن لك موقع صُناع
          أن وصولك إلى أو استخدام أي من خدمات موقع صُناع أو أي موقع مرتبط سيكون
          دون انقطاع أو خالٍ من الأخطاء أو عمليات الحذف، أو أن يتم تصحيح العيوب،
          أو أن خدمات موقع صُناع أو أي موقع مرتبط سيكون خالٍ من الفيروسات أو
          المكونات الضارة الأخرى. دون تقييد لما سبق ذكره، يتم تقديم جميع
          المحتويات الموجودة على أو من خلال خدمات موقع صُناع للمستخدمين "كما
          هي"، دون أي ضمانات من أي نوع، سواء كانت صريحة أو ضمنية، بما في ذلك على
          سبيل المثال لا الحصر الضمانات الضمنية للرواج والصلاحية لغرض معين، أو
          عدم الانتهاك، أو الأمان، أو الدقة.
        </p>
      </div>

      <div>
        <p dir="rtl">
          إن حالة المحتوى التي أشرنا اليها بـ"كما هي" تُعتبر وصف لأي تعامل ينشأ
          عن خدمات صُناع أو نتيجة لذلك. يُرجى العلم أن بعض السلطات القضائية قد
          لا تسمح باستثناء الضمانات الضمنية، لذلك قد لا تنطبق بعض الاستثناءات
          المذكورة أعلاه عليك. تحقق من القوانين المحلية الخاصة بك عن أي قيود أو
          موانع فيما يتعلق باستثناء الضمانات الضمنية.
        </p>
      </div>

      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Limitation of Liability"
        />
        <h1 className={style.h1}>حدود المسؤولية</h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Limitation of Liability"
        />
      </div>
      <p dir="rtl">
        <strong>(أ) </strong>إن مسؤولية (صناع مجمع للتعامل
        الإلكتروني-ذ.م.م-ش.ش.و) وشركاتها المنتسبة هي مسئولية منبثقة من مورد
        الخدمة او السلعة وهو المسئول ( مورد الخدمة او السلعة ) المسئولية الكاملة
        عنها دون مسؤولية على " صناع " حيث يقتصر طبيعة عمل صناع علي تسويق المنتج
        او الخدمة عبر منصتها فقط دون ادني مسئولية علي صناع عن أي مطالبات مهما
        كان نوعها الناشئة عن تقديم المنتجات أو الخدمات أو المتصلة بها إلى العميل
        أو علي نحو آخر ناشئة عن هذه الاتفاقية أو متصلة بها
      </p>
      <p dir="rtl">
        <strong>(ب) </strong>لن تكون شركة (صناع مجمع للتعامل
        الإلكتروني-ذ.م.م-ش.ش.و) بأي حال من الأحوال مسؤولة عن أي أرباح أو مدخرات
        ضائعة وفقدان بيانات أو فقدان سمعة أو خسارة شهرة، أو أضرار غير مباشرة أو
        عقابية أو خاصة أو استتباعيه، سواءً كانت تلك الأضرار قائمة على المسؤولية
        التقصيرية أو الضمان أو العقد أو حتى وإن كانت (صناع مجمع للتعامل
        الإلكتروني-ذ.م.م-ش.ش.و) قد أبلغت أو أنها كانت مدركة لإمكانية وقوع تلك
        الأضرار.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Submissions" />
        <h1 className={style.h1}>التعويض</h1>
        <img className={style.s_line} src={s_line} alt="Submissions" />
      </div>

      <p dir="rtl">
        أنت توافق على الدفاع عن موقع صُناع وبائعي موقع صُناع ويشمل ذلك شركتنا
        الأم أو شركاتنا الفرعية أو التابعة أو العاملين لدينا أو لديهم من مديرين
        أو أعضاء مجالس إدارة أو موظفين أو وكلاء أو موردين أو مقاولين من الباطن
        أو المرخصين من وضد جميع المطالبات والخسائر والأضرار والمسؤوليات
        والتكاليف (بما في ذلك على سبيل المثال لا الحصر، أتعاب المحاماة وتكاليف
        المحكمة المعقولة، الناتجة عن أو التي تتعلق بانتهاك هذه الشروط أو وصولك
        إلى أو استخدام خدمات موقع صُناع. يسري التزام التعويض السابق في حالة
        إنهاء هذه الشروط وإنهاء تشغيل خدمات موقع صُناع أو أي منتج أو خدمة مقدمة
        إليك ناشئة عن أو تتعلق باستخدامك لخدمات موقع صُناع.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Submissions" />
        <h1 className={style.h1}>التقديمات</h1>
        <img className={style.s_line} src={s_line} alt="Submissions" />
      </div>
      <p dir="rtl">
        باستثناء ما هو منصوص عليه صراحة بخلاف ذلك أو في بيان الخصوصية المنشور
        على موقع صُناع المنفصل، أي اتصال أو مادة تقوم بتقديمها إلى موقع صُناع من
        خلال خدمات الموقع بالبريد الإلكتروني أو غير ذلك، بما في ذلك على سبيل
        المثال لا الحصر أي مسابقة أو مشاركة في قرعات، أو صور، أو ملفات فيديو أو
        ملفات صوتية أو مقالات أو أسئلة أو تعليقات أو اقتراحات أو أي أفكار
        للميزات أو التعديلات أو التحسينات أو المنتجات أو التقنيات أو المحتوى أو
        العروض أو الاعلانات الترويجية أو الاستراتيجيات أو أسماء ميزات المنتجات
        أو أي مستندات ذات صلة أو أعمال فنية أو رموز كمبيوتر أو مخططات بيانية أو
        اي مواد أخرى (يُشار اليها مجتمعة، "بالتقديمات") ، سيتم التعامل معها على
        أنها غير سرية وغير مسجلة الملكية ويمكن استخدامها من قبل موقع صُناع أو
        بائعي صُناع أو الشركات التابعة له أو الكيانات ذات الصلة لأي غرض، بما في
        ذلك على سبيل المثال لا الحصر التعديل أو الإفصاح أو الإرسال أو النشر أو
        البث أو الإعلان أو الدعاية أو لأغراض ترويجية في أي وسيلة اعلامية بدون
        إذن أو موافقة أو دفع أو أي اعتبار آخر، ما لم يحظر القانون ذلك. وعلاوة
        على ذلك، فإن بائعي صُناع وموقع صُناع والشركات التابعة للموقع والكيانات
        المرتبطة به يتمتعون بحرية استخدام أي ملفات واردة في أي اتصال ترسله إلى
        موقع صُناع من خلال خدمات موقع صُناع لأي غرض كان، بما في ذلك على سبيل
        المثال لا الحصر، تطوير أو تصنيع وتسويق المنتجات أو الخدمات باستخدام
        المعلومات الواردة في هذه التقديمات. موقع صُناع ليس ملزماً بمراجعة، أو
        دراسة، أو تنفيذ طلبات التقديم الخاص بك، أو اعادة أو إرسال أي جزء منها لك
        لأي سبب.
      </p>
      <p dir="rtl">
        باستخدامك لخدمات صُناع ، فإنك توافق على أن موقع صُناع قد يتواصل معك عن
        طريق الرسائل النصية القصيرة SMS أو غيرها من الوسائل الإلكترونية الموجودة
        على جهازك المحمول، وأن بعض المعلومات حول استخدامك لخدمات صُناع قد يتم
        توصيلها إلى موقع صُناع. في حالة تغيير رقم هاتفك المحمول أو إلغائه، فإنك
        توافق على الفور على تحديث معلومات حساب اشتراكك المحمول لضمان عدم إرسال
        الرسائل التي يعتزم موقع صُناع إرسالها إليك إلى شخص آخر يحصل على رقم
        هاتفك المحمول السابق. يختلف عدد التنبيهات التي ستتلقاها بناءً على
        اختياراتك وعدد المرات التي تقوم فيها بالطلب من موقع صُناع.
      </p>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Privacy Policy" />
        <h1 className={style.h1}> سياسة الخصوصية </h1>
        <img className={style.s_line} src={s_line} alt="Privacy Policy" />
      </div>
      <p dir="rtl">
        نلتزم في موقع صُناع بالحفاظ على خصوصية أعضائنا وضمان أن تكون المعلومات
        الشخصية التي تزودنا بها آمنة. يحتاج موقع صُناع إلى معالجة البيانات
        والمعلومات الشخصية للمشترين والبائعين لتشغيل الأعمال وتزويدك بالخدمات.
        من خلال قبول شروط الاستخدام الخاصة بنا، أنت تؤكد أنك قد قرأت هذه السياسة
        وأنك توافق على استخدامنا لمعلوماتك بالطرق التي نوضحها. إذا كنت لا تريد
        منا جمع معلوماتك الشخصية أو معالجتها بالطرق الموضحة في هذه السياسة، فلا
        ينبغي لك استخدام الخدمات. نحن لسنا مسؤولين عن المحتوى أو سياسات الخصوصية
        لأي من أعضائنا، أو لمواقع الطرف الثالث، أو لتطبيقات الطرف الثالث.
      </p>
      <p dir="rtl">
        نحن نستخدم المعلومات التي نجمعها من المستخدمين بعدة طرق. على سبيل
        المثال، إذا اشتريت منتجًا ما، سنشارك معلومات عنوانك مع البائع؛ من الممكن
        استخدام اقتراحات الموقع الخاصة بك من قبلنا لهدف تحسين الخدمات؛ نحن
        نستخدم تحليلات البيانات ونعمل مع بعض موفري الخدمات التابعين لجهات خارجية
        لضمان وظائف الموقع التشغيلية، ولتحسين الخدمات، وللإعلان والتسويق
        المستهدف عبر الإنترنت، وبالطبع نستخدم عنوان بريدك الإلكتروني للتواصل
        معك. لن يقوم موقع صُناع ببيع معلوماتك الشخصية أو الكشف عنها لأطراف ثالثة
        دون موافقتك، باستثناء ما هو محدد في سياسة الخصوصية الخاصة بنا.
      </p>
      <p dir="rtl">
        كي تكون قادرًا على استخدام خدماتنا، تحتاج إلى تزويدنا بعنوان بريد
        إلكتروني صالح، وبالنسبة لعملية التسجيل، نحتاج الى اسم مرتبط بحسابك يمكنك
        اختياره ويمثل هويتك على موقع صُناع. يمكنك مراجعة هذا الاسم وتغييره من
        خلال إعدادات حسابك. يجب تقديم هذه المعلومات لتمكيننا من تزويدك بالخدمات.
        اعتمادًا على الخدمات التي تختار استخدامها، قد تكون هناك حاجة إلى معلومات
        إضافية، مثل اسم المتجر ومعلومات الفوترة والدفع (بما في ذلك عنوان إرسال
        الفواتير ورقم الهاتف ومعلومات بطاقة الائتمان) ورقم الهاتف و/أو العنوان
        البريدي الفعلي لكي نقدم خدمة معينة. لا يُطلب منك تزويدنا بهذه المعلومات
        للاشتراك، ولكننا سنحتاجها لتقديم خدمات معينة. على سبيل المثال، نحتاج إلى
        عنوان بريدي مادي إذا كنت تشتري شيئًا ما على الموقع للتسليم.
      </p>
      <p dir="rtl">
        يمكنك اختيار تقديم اسمك الكامل ومعلوماتك الشخصية الأخرى (مثل عيد الميلاد
        والجنس والموقع) وعرضها بشكل عام فيما يتعلق بحسابك ونشاطك. إذا كنت لا
        تريد عرض هذه المعلومات الإضافية بشكل عام، يمكنك إزالتها من خلال إعدادات
        حسابك.
      </p>
      <p dir="rtl">
        الاسم المرتبط بحسابك، والذي يمكنك مراجعته من خلال تغيير إعدادات الحساب،
        يتم عرضه علنًا وتوصيله بنشاطك في موقع صُناع. قد يرى أشخاص آخرون التاريخ
        الذي انضممت فيه، ومراجعاتك، وتقييماتك، ومعلومات ملفك الشخصي، والتعليقات
        في المساحات الاجتماعية.
      </p>
      <p dir="rtl">
        يستخدم موقع الويب ملفات تعريف الارتباط (كوكيز) وإشارات الويب والأدوات
        التقنية المشابهة لجمع معلومات حول وصولك إلى موقع الويب والخدمات التي
        نقدمها. يرجى العلم بأن منع تشغيل ملفات تعريف الارتباط سيكون له تأثير على
        وظائف الموقع وقد يمنعك من استخدام الخدمات. - ملفات الكوكيز هي عبارة عن
        أجزاء من المعلومات تتضمن رمزًا مرجعيًا فريدًا ينقله موقع الويب إلى جهازك
        لتخزين معلوماتك عنك وأحيانًا تعقبها. لا يستمر عدد من ملفات الكوكيز التي
        نستخدمها إلا لمدة جلسة الويب الخاصة بك وتنتهي صلاحيتها عند إغلاق
        المتصفح. يتم استخدام ملفات كوكيز أخرى لتذكرك عندما تعود إلى موقع الويب
        والاستمرار لفترة أطول.
      </p>
      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Refunds and Returned Goods"
        />
        <h1 className={style.h1}>المبالغ المستردة والسلع المُرجعة </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Refunds and Returned Goods"
        />
      </div>
      <strong>
        من خلال التسجيل للبيع على موقع صُناع يوافق البائع على خصم رسوم الشحن
        ورسوم عمولة موقع صُناع من مدفوعاته وتقديم المبالغ المستردة للمشترين
        الذين لم يتلقوا منتجاتهم؛ أو الذين يتلقون المنتج متأخرين؛ أو إذا كان
        المنتج لا يطابق مواصفاته المذكورة في الوصف. يتم ذلك كما هو موضح أدناه:
      </strong>
      <p dir="rtl">
        <strong>
          (أ)- لا توصيل: إذا لم يستلم المشتري منتج ما، كما هو موضح أدناه، يجب
          على البائع استرداد المبلغ المدفوع للمشتري:
        </strong>
        <p>
          {" "}
          <strong> 1- </strong> في حال لم يتلقى المشتري المنتج المطلوب لأنه لم
          يتم إرسال المنتج.{" "}
        </p>
        <p>
          {" "}
          <strong> 2- </strong> في حال لم يتلقى المشتري هذا المنتج لأنه لم يتم
          إرساله إلى العنوان الموجود على موقع صُناع.{" "}
        </p>
        <p>
          <strong> 3- </strong> في حال عدم توفر أي دليل على شحن المنتج إلى
          العنوان المطلوب.{" "}
        </p>
      </p>
      <p dir="rtl">
        <p>
          {" "}
          <strong>
            (ب)- التسليم المتأخر: إذا قدم المشتري دليلاً على استيفاء جميع هذه
            الشروط، يجب على البائع استرداد المبالغ المدفوعة للمشتري:
          </strong>{" "}
        </p>
        <p>
          {" "}
          <strong> 1- </strong> إذا تم طلب المنتج لتاريخ أو مناسبة محددة.
        </p>
        <p>
          {" "}
          <strong> 2- </strong> يتم اعتبار المنتج عديم الفائدة بعد ذلك التاريخ.
        </p>
        <p>
          {" "}
          <strong> 3- </strong> إذا لم يقم البائع بشحن المنتج حسب وقت معالجته أو
          في التاريخ المتفق عليه في المحادثات.
        </p>
      </p>
      <p dir="rtl">
        <p>
          <strong>
            (ج)- عدم تطابق المنتج مع المواصفات المذكورة: إذا أثبت المشتري أن أي
            مما يلي صحيحًا، يجب على البائع استرداد المبلغ المدفوع للمشتري:
          </strong>{" "}
        </p>
        <p>
          <strong> 1- </strong> إذا كان المنتج مختلفًا تمامًا عن وصف القائمة أو
          الصور.
        </p>
        <p>
          <strong> 2- </strong> إذا كان المنتج الذي تم تلقيه ذا لون أو طراز أو
          إصدار أو حجم مختلف عما هو معروض في الصورة أو كما هو موضح في الوصف.
        </p>
        <p>
          <strong> 3- </strong> إذا كان المنتج ذا تصميم أو مادة مختلفة.
        </p>
        <p>
          <strong> 4- </strong> إذا تم الإعلان عن المنتج أنه أصلي وتبين غير ذلك.
        </p>
        <p>
          <strong> 5- </strong> فشل البائع في الكشف عن حقيقة تلف منتج ما أو
          فقدان أجزاء منه.
        </p>
        <p>
          <strong> 6- </strong> في حالة تعرض المنتج للضرر وثبت أن التغليف غير
          المناسب هو الذي تسبب في الضرر.
        </p>
        <p>
          <strong> 7- </strong> إذا كانت كمية المنتجات التي تم طلبها مختلفة عن
          ما تم استلامه.
        </p>
        <p>
          <strong> 8- </strong> إذا تم وصف حالة المنتج بشكل غير صحيح. على سبيل
          المثال، ذُكر في وصف المنتج وقت الشراء أن المنتج "جديد" وتبين انه
          مستخدم.
        </p>
      </p>
      <p dir="rtl">
        أنت مسؤول حال وجود عدم مطابقة أو خلل في أي من منتجاتك أو منتجاتك الأخرى
        المرتبطة بمنتجاتك أو أي استرجاع عام أو خاص لها. سوف تقوم بإعلامنا على
        الفور بمجرد أن تكون على علم بأي استرجاع عام أو خاص لمنتجاتك أو المنتجات
        الأخرى المتعلقة بمنتجاتك ، وفى حال مخالفة ذلك لا غضاضة فى قيام صناع من
        إتخاذ الأجراءات القانونية حيال ذلك .
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Food and Drinks" />
        <h1 className={style.h1}>الأطعمة والمشروبات</h1>
        <img className={style.s_line} src={s_line} alt="Food and Drinks" />
      </div>
      <p dir="rtl">
        تنطبق الشروط الخاصة التالية على بيع منتجات (الأطعمة والمشروبات) على موقع
        صناع{" "}
      </p>
      <p dir="rtl">
        <strong>- </strong> يجب على البائع ان يملك رخصة تجارية خاصة به وتصاريح
        البلدية المرتبطة بها لبيع منتجات صالحة للأكل على موقع صناع.
      </p>
      <p dir="rtl">
        <strong>- </strong>البائع هو المسؤول عن إعداد المواد الصالحة للأكل
        بطريقة نظيفة وصحية ووفقا لأي قوانين معمول بها.
      </p>
      <p dir="rtl">
        <strong>- </strong>لا يُسمح ببيع الأطعمة الساخنة أو المجمدة التي تحتوي
        على الكحول.
      </p>
      <p dir="rtl">
        <strong>- </strong>يجب أن يتم وصف المواد الغذائية بوضوح من خلال قائمة
        للمكونات المستخدمة وتواريخ الانتاج وانتهاء الصلاحية وأي تعليمات خاصة أو
        متطلبات تخزين وفقا لأية قوانين معمول بها.
      </p>
      <p dir="rtl">
        <strong>- </strong>يجب تعبئة المواد الغذائية بشكل صحيح لتسليمها لتجنب أي
        تلوث أو تلف. يجب أن يتضمن سعر المنتجات القابلة للتلف، كالسلع المخبوزة
        الطازجة، التسليم في نفس اليوم.
      </p>
      <p dir="rtl">
        <strong>- </strong>يحظر على أي عضو لا يلتزم بهذه القواعد بيع الاطعمة
        والمشروبات على موقع صناع
      </p>
      <p dir="rtl">
        <strong>-</strong>
        لا يقوم موقع صُناع ببيع أي مواد غذائية مدرجة على الموقع بشكل مباشر، نحن
        نقوم بتحصيل المدفوعات فقط كقناة بيع للبائع، وبالتالي فإننا لا نضمن أي
        منتج مدرج على الموقع أو أي معاملة بين المشتري والبائع. ليس لدينا أي
        سيطرة ولا نضمن جودة أو سلامة أو قانونية المنتجات المعلن عنها، أو صحة أو
        دقة قوائم المستخدمين، أو قدرة البائعين على بيع المنتجات، أو قدرة
        المشترين على دفع ثمن المواد، أو ما إذا كان المشتري او البائع سيكمل
        فعليًا عملية بيع أو شراء المنتج كاملة أو إذا كان سيعيد المنتج ، وبالتالى
        لا مسؤولية على " صناع " عند مخالفة البائع أو المشترى ذلك .
      </p>

      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Health and Beauty Products"
        />
        <h1 className={style.h1}>الصحة والجمال</h1>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Health and Beauty Products"
        />
      </div>
      <p dir="rtl">
        لدى منتجات الماكياج ومستحضرات التجميل والصحة سياسة عدم استرداد/تبديل
        صارمة بمجرد فتحها.
      </p>
      <p dir="rtl">
        يجب أن تمتثل منتجات الماكياج ومستحضرات التجميل والصحة للوائح الإمارات
        العربية المتحدة الخاصة بمنتجات ومستحضرات التجميل والصحة، ويجب أن يتطلع
        البائعين الذين يقومون بإنتاج هذه المنتجات على هذه اللوائح والالتزام بها
        والحصول على تصاريح محددة ورخص تجارية لبيع هذه المنتجات حيثما ينطبق ذلك
        وتوفير الاعتمادات اذا تطلب الأمر
      </p>
      <p dir="rtl">
        لا يتحمل موقع صُناع أي مسؤولية عن منتجات الصحة والجمال التي تباع على
        الموقع ولا تضمن استخدام هذه المنتجات أو فعاليتها أو سلامتها كما هو معلن
        من قبل البائع.
      </p>
      <strong>ضريبة القيمة المضافة</strong>
      <p dir="rtl">
        يُعتبر موقع صناع مسجلاً لضريبة القيمة المضافة ويلتزم بأنظمة ضريبة القيمة
        المضافة في دولة الإمارات العربية المتحدة. على هذا النحو، سيقوم موقع صناع
        بفرض ضريبة إضافية بنسبة 5 ٪ لكل منتج عند اتمام عملية الدفع، والتي ستكون
        مرئية للمشترين الذين سيتمكنون من رؤية تكلفة عنصرهم بالإضافة إلى ضريبة
        القيمة المضافة بنسبة 5 ٪ المحسوبة عند الدفع. سيقوم المشتري بدفع السعر
        الإجمالي بالإضافة إلى ضريبة القيمة المضافة بنسبة 5 ٪ وسوف يتعهد موقع
        صناع بتقديم ضريبة القيمة المضافة إلى السلطات المختصة.
      </p>
      <p dir="rtl">
        لا يُطلب من البائعين الفرديين في موقع صناع التسجيل للحصول على ضريبة
        القيمة المضافة كي يتمكنوا من البيع على موقع صناع.
      </p>
      <p dir="rtl">
        تتبع القوانين واللوائح والقرارات والمراسيم بشأن تطبيق الضرائب الواجبة
        السداد ، وهو ما يستتبع تلقائياً تعديل نسبة الضريبة المبينة بموقع صناع
        دون حاجة إلى أخطار أو أنذار خاص .
      </p>
      <p dir="rtl">
        موقع صناع غير مسؤول ولا يقدم المشورة القانونية بشأن حالة ضريبة القيمة
        المضافة والتزامات البائعين تجاهها أو فيما يتعلق بالمبيعات التي يجريها
        البائعين على قنوات بيع أخرى غير صناع
      </p>
    </div>
  );
}
