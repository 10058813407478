import style from "./style.module.css";
import useApi from "../../components/Loading/LoadingApi";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/Layout/BreadCrumb";
import { h_line, s_line } from "../../assets";
import { isMobileApp } from "../../utils/functions";
export default function SellWithUsEn() {
  useApi(600);
  const { i18n } = useTranslation();
  return (
    <div
      className={style.Content}
      style={{
        direction: i18n.language === "en" ? "ltr" : "ltr",
        textAlign: i18n.language === "en" ? "justify" : "justify",
      }}
    >
      {!isMobileApp && <img className={style.image_h_line} src={h_line} alt="h_line" />}
      {!isMobileApp &&<BreadCrumb />}
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Sell With Us
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          The terms and conditions under which purchases are supplied and
          delivered to you as a buyer on{" "}
          <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
            sona3.ae
          </a>{" "}
          Website or on our mobile application (collectively referred to as the
          “Site”) are owned by (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C)
          in the United Arab Emirates under license number CN-4899615 at the
          Department of Economic Development in Abu Dhabi.
        </p>
        <p dir="ltr">
          These terms and conditions, hereinafter referred to as the "Terms",
          govern the sale and supply of all products and equipment, and all
          services provided to the customer by SONA ETRANSACTION COMPLEX - L. L.
          C - O. P. C (hereinafter referred to as “SONA ETRANSACTION COMPLEX -
          L. L. C - O. P. C").{" "}
        </p>

        <p dir="ltr">
          These are the general terms and conditions (“Terms”) that are
          concluded between you and (SONA ETRANSACTION COMPLEX - L. L. C - O. P.
          C).
        </p>

        <p dir="ltr">
          With regard to your use of this site, your access to it, and your use
          of the mobile services of (SONA ETRANSACTION COMPLEX - L. L. C - O. P.
          C), the services of the dial-up and voice response unit at (SONA
          ETRANSACTION COMPLEX - L. L. C - O. P. C), and the software
          applications for the (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C)
          Website that were downloaded to the desktop. Your e-mail addresses,
          and the other sites and services on which these terms have been
          published or referred to (“SONA ETRANSACTION COMPLEX - L. L. C - O. P.
          C”) and the promises and obligations stipulated here, which you, in
          turn, intend to be legally bound by, you and (SONA ETRANSACTION
          COMPLEX - L. L. C - O. P. C) agree to the following:
        </p>
        <ul>
          <li>
            <strong>1.</strong>Your access to and use of the services of (SONA
            ETRANSACTION COMPLEX - L. L. C - O. P. C) is subject to these terms,
            which include the privacy policy statement and other separate
            publications or policies, in addition to any amendments added by
            (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) in addition to all
            applicable laws and regulations.{" "}
          </li>
          <li>
            <strong>2.</strong>If any of SONA3 Services has separate terms and
            conditions of use, privacy statement or other policy (“Separate
            Terms”), then those separate terms, which may be amended from time
            to time, shall apply in any action related to your use of those
            Services set forth by SONA3. In the event of any direct conflict
            between the Separate Terms and these Terms, the Separate Terms shall
            prevail.
          </li>
          <li>
            <strong>3.</strong>By using SONA3 Services, you agree to be bound by
            these Terms and any other applicable policies. If you do not wish to
            be bound by these terms or policies, do not use SONA3 Website
            services.
          </li>
          <li>
            <strong>4.</strong>SONA3 services provide information related to
            various products and services, in addition to the opportunity to
            obtain additional information regarding those products and services
            and the opportunity to purchase them. These terms and information
            provided by SONA3 Website services do not in any way exceed the
            terms and conditions of purchase for any product or service except
            as specified here. In the event that any section within the Site or
            any feature provided by SONA3 Site and Services includes specific
            terms and conditions regarding its use (“Specific Terms”), such
            specific terms shall be added to these Terms and shall apply. In the
            event of any direct conflict between these Terms and the Specific
            Terms, the Specific Terms shall prevail.{" "}
          </li>
          <li>
            <strong>5.</strong>Displaying your business and products on SONA3
            means the whole world shall see it. You should be aware that
            publishing your work on our website shall attract some legal
            responsibilities. You should take care to ensure that you are aware
            of all these responsibilities and make yourself aware of the
            requirements of this document. It is important that you read and
            understand the agreement below. Your use of this Website signifies
            your acceptance of this Agreement and any changes made after
            commencement of use.
          </li>
        </ul>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Changes in Terms" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          {" "}
          Changes in Terms{" "}
        </h1>
        <img className={style.s_line} src={s_line} alt="Changes in Terms" />
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong> SONA3 Website has the right at any time and without
          prior notice, based on its sole discretion, to revise these terms or
          impose new terms and conditions regarding access to or use of SONA3
          Website services. Such revisions and additions shall be effective
          immediately upon notice thereof, which may be provided by any means,
          including, without limitation, by posting the revised or additional
          terms and conditions on SONA3 Services.
        </p>

        <p dir="ltr">
          <strong>-</strong> You are responsible for reviewing these Terms
          periodically, for any modification to these Terms that may affect your
          rights or obligations under this Agreement.
        </p>

        <p dir="ltr">
          <strong>‌-</strong> You agree that you shall be deemed to be aware of
          any modification by SONA3 to these Terms. Any access or use of SONA3
          Services by you after amendments or additions to these terms shall be
          considered your acceptance of these amendments or additions.
        </p>
        <p dir="ltr">
          <strong>‌-</strong>
          You agree to comply with all applicable laws, regulations and decrees
          regarding your use of SONA3 Website services and your purchase of
          products or services through such laws. No modification of these Terms
          by any party other than SONA3 shall be valid or enforceable against
          SONA3 unless expressly agreed to by SONA3 in a writing signed by a
          duly authorized officer of SONA3.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Close the Account
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          <strong>A‌-</strong>
          These terms are effective until terminated by SONA3. SONA3 may
          terminate these Terms without notice and at any time with respect to
          any SONA3 Website services.
        </p>
        <p dir="ltr">
          <strong>B-</strong>
          In the event of termination, access to SONA3 Services shall no longer
          be authorized to you, and the restrictions imposed on you with respect
          to the content, disclaimers, indemnities, and limitations of
          liabilities set forth in these Terms shall apply.
        </p>
        <p dir="ltr">
          <strong>C-</strong>
          SONA3 shall also have the right at any time and without any prior
          notice to terminate some or all of SONA3 Website services or any
          feature or part thereof, or any products or services provided through
          them, or to terminate any person’s right to access SONA3 Website
          services or use or access and use any feature or part thereof.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          User Eligibility
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          <strong>A‌-</strong>
          The user acknowledges that he has the legal capacity to deal with the
          store, or that he is at least eighteen years old.
        </p>

        <p dir="ltr">
          <strong>B‌-</strong>
          The user agrees that if he violates this Clause, he shall bear the
          consequences of this violation before others.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Listing of Products and Sell
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          SONA3 reserves the right to modify or change the location of display
          of the product or service within the application or website if
          technical matters necessitate this change or modification.
        </p>
        <p dir="ltr">
          (SONA ETRANSACTION COMPLEX - L. L. C - O. P. C) provides a platform
          for independent shoppers and sellers to buy and sell goods. Sellers
          may list products and conduct sales transactions for items and goods
          that they can legally sell. Any item that does not meet this standard
          shall be removed at SONA3's discretion and the user's account shall be
          evaluated for possible termination. SONA3 reserves the right to
          review, restrict, suspend or terminate a user's account at any time
          without explanation or notice. SONA3 reserves the right to remove
          content deemed inappropriate, harmful or illegal.
        </p>
        <p dir="ltr">
          By accessing and using the Site, Sellers warrant that the products
          offered for sale are described with complete accuracy.
        </p>
        <p dir="ltr">
          A transaction between a buyer and a seller is a legally binding
          contract between both parties.
        </p>
        <p dir="ltr">
          The seller must guarantee the condition of the items or goods when
          sold and may not misrepresent the item to be sold in any way or
          misdescribe it. Users are prohibited from sharing accounts or using
          another user's account without permission. Violation of this policy
          shall result in an investigation by SONA3, and may result in
          termination of the user’s account or other corrective measures deemed
          appropriate by{" "}
          <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
            sona3.ae
          </a>
          , taking into account the right of SONA3 to take legal action in this
          regard.
        </p>
        <p dir="ltr">
          By using SONA3 platform, you agree that (SONA ETRANSACTION COMPLEX -
          L. L. C - O. P. C) is not responsible for determining the taxes
          applicable to your business and that you shall ensure that all
          appropriate taxes are remitted to the relevant tax authorities.
        </p>
        <p dir="ltr">
          The service or commodity supplier or seller must have a valid business
          license of its own, and the service or commodity supplier or seller is
          obligated to renew the business license periodically when its license
          ends on the SONA3 platform, and the service or commodity supplier or
          seller agrees that in the event of its violation of this clause, it
          will bear the consequences of this violation before others and that it
          is fully responsible for the consequences of not renewing the business
          license.
        </p>
      </div>
      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Payment Services in SONA3"
        />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Payment Services in SONA3
        </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Payment Services in SONA3"
        />
      </div>
      <div>
        <p dir="ltr">
          <strong>
            SONA3, through its partners, provides payment system in (SONA
            ETRANSACTION COMPLEX - L. L. C - O. P. C). It can be done entirely
            online through the payment options available on the “SONA3” platform
            or through any payment method provided by (SONA ETRANSACTION COMPLEX
            - L. L. C - O. P. C) from time to time, for example:
          </strong>
        </p>

        <p dir="ltr">
          <strong>A-</strong>
          Through a credit or debit card.
        </p>

        <p dir="ltr">
          <strong>B-</strong>
          Through your electronic wallet.
        </p>

        <p dir="ltr">
          <strong>C‌-</strong>
          Cash on delivery (an amount not exceeding AED 2,500).
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Prohibited Items and Goods
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          Hazardous chemicals
        </p>

        <p dir="ltr">
          <strong>-</strong>
          Pirated materials.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Counterfeit goods or brands.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Personal data or information.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Items or products that carry or transmit hidden code or signals with
          the intent to cause harm or trace personal information or transactions
          of any kind.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Items or products not described or displayed correctly.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Material that is harmful, harassing, intimidating or defamatory.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Hazardous materials, products or substances.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Prostitution or other similar services.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Items that violate our Terms of Service or Terms of Use
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Any item or service that violates applicable local, state, federal or
          international law in connection with the manufacture, distribution,
          advertising or transmission of said substance.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Stolen items.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          fireworks.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Adult / pornographic material not suitable for children under 18.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Drugs
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Alcohol
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Tobacco
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Mass produced electronics
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Protected monuments.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Real estate.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Ivory or animal products from endangered species collected illegally.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Automotive, or parts for automobiles or recreational vehicles.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Items that are not in your possession or items that you do not intend
          to deliver.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Illegal, counterfeit or smuggled goods of any kind.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Materials or products that are copyrighted, trademarked or patented by
          another party.
        </p>
        <p dir="ltr">
          Any product or service that{" "}
          <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
            sona3.ae
          </a>{" "}
          determines, now or in the future, to be inappropriate, harmful,
          fraudulent, or illegal, as well as in a manner that does not violate
          the laws and regulations in force in the country.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Independent Store Policies
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          We encourage all users who choose to open a store on SONA3 website to
          create and post a list of stores and purchasing policies outlining the
          specific details of their store. These policies shall include, but are
          not limited to: accepted payment processors, exchange and return
          policy, and sales policies. All store policies must comply with the
          acceptable use and privacy policies set forth on{" "}
          <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
            sona3.ae
          </a>
          . In the event that store policies conflict with the terms of use of{" "}
          <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
            sona3.ae
          </a>
          , the terms of use shall supersede any other store policies.
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Payments
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <p dir="ltr">
          - Payments to merchants will be settled and processed and transferred
          to their bank account Monthly, taking into account the passage of 30
          days from the last day of the settlement period:
        </p>
        <p>
          1. The settlement period: from the 1th to the last day of the month
        </p>
        <p>
          2. Example: Payments for sales made from July 1 to July 31 will be
          paid on September
        </p>
        <p>
          - The payments shall include orders that have been shipped during the
          specified settlement period.
        </p>
        <p>
          - The seller agrees to deduct a Commission of (SONA ETRANSACTION
          COMPLEX - L.L.C - O.P.C) from the sales of the item or service listed
          on the electronic platform upon stating such on SONA3 platform.
        </p>
        <p>
          - SONA3 platform shall- at any time- have the right to collect
          subscription fees for marketing services, products or goods upon
          stating such fees on SONA3 platform.
        </p>
      </div>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Notice and Procedure for Claims of Intellectual Property Rights
          Infringement
        </h1>
        <img className={style.s_line} src={s_line} alt="Sell With Us" />
      </div>
      <div>
        <strong>
          If you believe that your work has been copied in a way that
          constitutes copyright infringement or that your intellectual property
          rights have been violated in any way, please provide SONA3's Copyright
          Agent with the written information specified below:
        </strong>
        <p dir="ltr">
          <strong>-</strong>
          An electronic or physical signature of the person authorized to act on
          behalf of the owner of the copyright or other intellectual property
          interest.
        </p>
        <p dir="ltr">
          <strong>-</strong>A description of the copyrighted work or other
          intellectual property that you claim has been infringed.
        </p>
        <p dir="ltr">
          <strong>-</strong>A description of where the material that you claim
          is infringing is located on SONA3 website.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          Your address, telephone number, fax number and email address.
        </p>
        <p dir="ltr">
          <strong>-</strong>- A statement by you that you have a good faith
          belief that the disputed use is not authorized by the copyright or
          intellectual property owner, its agent, or the law.
        </p>
        <p dir="ltr">
          <strong>-</strong>A statement by you, made under penalty of perjury,
          that the above information in your notice is accurate and that you are
          the copyright or other intellectual property owner or are authorized
          to act on behalf of the copyright owner or other intellectual property
          owner.
        </p>
        <p dir="ltr">
          <strong>-</strong>
          SONA3's Copyright Agent for notice of claims of copyright or other
          intellectual property rights infringement can be reached by writing to{" "}
          <a
            href="mailto:info@sona3.ae"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            info@sona3.ae
          </a>
          .
        </p>
      </div>
      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Prices and Orders" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Prices and Orders
        </h1>
        <img className={style.s_line} src={s_line} alt="Prices and Orders" />
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          SONA3 shall add shipping and handling fees and applicable sales / use
          tax on top of the price paid by the buyer.
        </p>
      </div>

      <div>
        <p dir="ltr">
          <strong>-</strong>
          All our shipping fees are calculated based on the volumetric weight of
          the product. The volumetric weight of the product is: width x length x
          height in centimeters / 5000 = volumetric weight in kilograms.
        </p>
      </div>

      <div>
        <p dir="ltr">
          <strong>-</strong>
          SONA3 Website reserves the right, without prior notice, to stop or
          change the specifications and prices on the products and services
          provided through SONA3 Website services without incurring any
          obligation towards you. Descriptions or references to products or
          services provided by SONA3 Website do not imply support for that
          product or service, and do not constitute any warranty of its kind by
          SONA3 Website.
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          SONA3 website reserves the right, without prior notice, to deduct the
          remaining amount of the shipping fees from the service or commodity
          supplier in the event that SONA3 website discovers at any time that
          the volumetric weight of the product mentioned by the service or
          commodity supplier on SONA3 website does not match the actual
          volumetric weight of the product when delivered to the buyer.
          Therefore, the service or commodity supplier must mention the actual
          volumetric weight of the product on SONA3 website.
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          Your order confirmation receipt does not constitute SONA3 Website’s
          approval of the order. Before the Site accepts the order, it may
          require verification of the information and approval of the order.
          SONA3 Website reserves the right at any time after receiving your
          order to accept or reject your order, or any part of it, even after
          you receive the order confirmation message from SONA3 Website, for any
          reason. SONA3 reserves the right to limit the order quantity on any
          product and refuse service to any customer without prior notice.
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          In the event that an incorrect price is listed for a product or
          service due to incorrect information from sellers or a typographical
          error, SONA3 has the right to refuse or cancel orders placed for the
          product or service listed at the incorrect price, regardless of
          whether the order has been confirmed.
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>-</strong>
          The risk of loss for all products that you purchase and are shipped by
          SONA3 Website is transferred to you when SONA3 Website delivers the
          product to the shipping company to deliver it to you.
        </p>
      </div>
      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Cancelling The Orders"
        />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Cancelling The Orders
        </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Cancelling The Orders"
        />
      </div>
      <div>
        <p dir="ltr">
          <strong>
            A- The customer’s order can be canceled and the amount paid can be
            refunded if the following occurs:
          </strong>
        </p>
      </div>

      <div>
        <ol className="sell_with_us" dir="ltr">
          <li>
            If the seller does not accept the order, otherwise if the seller
            accepts the order, the cancellation policy is invalid.
          </li>
          <li>If the seller cancels the order.</li>
          <li>In the event of any technical problem with the application.</li>
          <li>
            SONA3 has the right to decide to cancel any customer's order if
            there is suspicion that the customer's account is fake.
          </li>
        </ol>
      </div>
      <div>
        <p dir="ltr">
          <strong>
            B- Refunds shall be returned to the payment method the customer
            initially used.
          </strong>
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>
            C- You can cancel the order and get a full refund until the order is
            accepted by the seller. If you need to cancel the order after it has
            been accepted by the seller, please contact us.
          </strong>
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>
            D - SONA3 has the right not to issue full or partial refunds if you
            cancel the order after accepting it.
          </strong>
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>
            E- SONA3 has the right to cancel any order, before or after
            acceptance, and you shall be notified immediately of any such
            cancellation.
          </strong>
        </p>
      </div>
      <div>
        <p dir="ltr">
          <strong>
            F- Customers who qualify for the cancellation policy, the refund
            process shall take up to 14 to 21 business days. Please contact us
            at ({" "}
            <a
              href="tel:+971600505006"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "sans-serif",
              }}
            >
              +971 60 050 5006
            </a>
            ), SONA3 customer support team shall verify the cancellation request
            and start the cancellation process.
          </strong>
        </p>
      </div>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Disclaimer" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Disclaimer and Limitation of Liability Regarding SONA3 Website and the
          Content Contained Therein
        </h1>
        <img className={style.s_line} src={s_line} alt="Disclaimer" />
      </div>
      <div>
        <p dir="ltr">
          SONA3 Site makes no warranties or representations whatsoever regarding
          the Site Services or any Site or content linked thereto, including the
          availability, accuracy, completeness or suitability of any sites,
          content, information and materials related thereto. SONA3 Website also
          does not guarantee to you that your access to or use of any of SONA3
          Website services or any linked Site shall be uninterrupted or free of
          errors or deletions, or that defects shall be corrected, or that SONA3
          Website services or any linked Site shall be free of viruses. or other
          harmful ingredients. Without limiting the foregoing, all content on or
          through SONA3 Site Services is provided to users “as is,” without
          warranties of any kind, whether express or implied, including but not
          limited to the implied warranties of merchantability and fitness for a
          particular purpose, non-infringement, security, or accuracy.
        </p>
      </div>

      <div>
        <p dir="ltr">
          The condition of the content that we refer to as “as is” is considered
          a description of any dealings arising from or as a result of SONA3’s
          services. Please note that some jurisdictions may not allow the
          exclusion of implied warranties, so some of the above exclusions may
          not apply to you. Check your local laws for any restrictions or
          restrictions regarding the exclusion of implied warranties.
        </p>
      </div>

      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt="Limitation of Liability"
        />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Limitation of Liability
        </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt="Limitation of Liability"
        />
      </div>
      <p dir="ltr">
        <strong>(A)</strong> The responsibility of (SONA ETRANSACTION COMPLEX -
        L. L. C - O. P. C) and its affiliated companies is a responsibility
        emanating from the supplier of the service or commodity, and he (the
        supplier of the service or commodity) is fully responsible for it, as
        the nature of the work of SONA3 is limited to marketing the product or
        service through its platform only without any responsibility on SONA3
        for any claims of any kind arising from or relating to the provision of
        products or services to the customer or otherwise arising from or
        connected with this agreement.
      </p>
      <p dir="ltr">
        <strong>(B)</strong> In no event shall (SONA ETRANSACTION COMPLEX - L.
        L. C - O. P. C) be liable for any lost profits or savings, loss of data,
        loss of reputation or loss of goodshall, or indirect, punitive, special,
        or consequential damages, whether or not those damages are based on
        tort, warranty, or contract, or even if (SONA ETRANSACTION COMPLEX - L.
        L. C - O. P. C) was informed or was aware of the possibility of such
        damages occurring.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Indemnification" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Indemnification
        </h1>
        <img className={style.s_line} src={s_line} alt="Submissions" />
      </div>
      <p dir="ltr">
        You agree to defend the Sona3 website and its vendors, including our
        parent company, subsidiaries, affiliates, and all personnel such as
        directors, board members, employees, agents, suppliers, subcontractors,
        and licensees, against all claims, losses, damages, liabilities, and
        costs (including, but not limited to, reasonable attorneys' fees and
        court costs) arising from or related to your violation of these terms or
        your access to or use of the Sona3 website's services. This
        indemnification obligation remains in effect even if these terms are
        terminated, the operation of the Sona3 website services is discontinued,
        or any product or service related to your use of the Sona3 website is
        ended.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Submissions" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Submissions
        </h1>
        <img className={style.s_line} src={s_line} alt="Submissions" />
      </div>
      <p dir="ltr">
        Except as expressly stated otherwise or in the privacy policy posted on
        the separate Sona3 website, any communication or material you submit to
        the Sona3 website through its services, via email or
        otherwise—including, but not limited to, any contest entries,
        sweepstakes, images, video or audio files, articles, questions,
        comments, suggestions, feature ideas, modifications, enhancements,
        products, technologies, content, offers, promotional advertisements,
        strategies, product feature names, related documents, artwork, computer
        code, graphical diagrams, or any other materials (collectively referred
        to as "Submissions")—will be treated as non-confidential and
        non-proprietary. Such Submissions may be used by the Sona3 website, its
        vendors, affiliates, or related entities for any purpose, including, but
        not limited to, modification, disclosure, transmission, publication,
        broadcasting, advertising, or promotional purposes in any media without
        permission, consent, compensation, or any other consideration unless
        prohibited by law. Furthermore, the vendors of Sona3, the Sona3 website,
        its affiliates, and related entities are free to use any files included
        in any communication you send to the Sona3 website through its services
        for any purpose, including, but not limited to, developing,
        manufacturing, and marketing products or services using the information
        contained in these Submissions. The Sona3 website is not obligated to
        review, consider, implement, return, or forward any part of your
        Submissions for any reason.
      </p>
      <p dir="ltr">
        By using Sona3 services, you agree that the Sona3 website may contact
        you via SMS text messages or other electronic means on your mobile
        device, and that certain information about your use of Sona3 services
        may be transmitted to the Sona3 website. If you change or deactivate
        your mobile phone number, you agree to immediately update your mobile
        subscription account information to ensure that messages intended for
        you are not sent to someone else who acquires your previous mobile
        number. The number of alerts you receive will vary based on your
        preferences and how often you make requests from the Sona3 website.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Privacy Policy" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Privacy Policy
        </h1>
        <img className={style.s_line} src={s_line} alt="Privacy Policy" />
      </div>
      <p dir="ltr">
        On SONA3 Website, we are committed to maintaining the privacy of our
        members and ensuring that the personal information you provide to us is
        secure. SONA3 needs to process the personal data and information of
        buyers and sellers to operate the business and provide you with
        services. By accepting our terms of use, you confirm that you have read
        this policy and that you agree to us using your information in the ways
        we explain. If you do not want us to collect or process your personal
        information in the ways described in this Policy, you should not use the
        Services. We are not responsible for the content or privacy policies of
        any of our members, third party Websites, or third-party applications.
      </p>
      <p dir="ltr">
        We use the information we collect from users in several ways. For
        example, if you purchase a product, we shall share your address
        information with the seller; Your Site suggestions may be used by us to
        improve the Services; We use data analytics and work with certain
        third-party service providers to ensure the operational functionality of
        the Site, to improve the Services, for targeted online advertising and
        marketing, and of course we use your email address to communicate with
        you. SONA3 shall not sell or disclose your personal information to third
        parties without your consent, except as specified in our Privacy Policy.
      </p>
      <p dir="ltr">
        In order to be able to use our services, you need to provide us with a
        valid email address, and for the registration process, we need a name
        associated with your account that you can choose and that represents
        your identity on SONA3 Website. You can review and change this name
        through your account settings. This information must be provided to
        enable us to provide you with the Services. Depending on the Services
        you choose to use, additional information, such as store name, billing
        and payment information (including billing address, phone number, and
        credit card information), telephone number, and / or physical mailing
        address may be required in order for us to provide a particular Service.
        You are not required to provide us with this information to sign up, but
        we shall need it to provide certain services. For example, we need a
        physical mailing address if you buy something on the Site for delivery.
      </p>
      <p dir="ltr">
        You may choose to provide your full name and other personal information
        (such as birthday, gender, and location) and display it publicly in
        connection with your account and activity. If you do not want this
        additional information to be displayed publicly, you can remove it
        through your account settings.
      </p>
      <p dir="ltr">
        The name associated with your account, which you can review by changing
        your account settings, is publicly displayed and associated with your
        activity on SONA3 site. Other people may see the date you joined, your
        reviews, ratings, profile information, and comments in social spaces.
      </p>
      <p dir="ltr">
        The Website uses cookies, web beacons and similar technical tools to
        collect information about your access to the Website and the services we
        offer. Please note that blocking cookies shall have an impact on the
        functionality of the Site and may prevent you from using the Services.
        Cookies are pieces of information that include a unique referral code
        that a website transfers to your device to store and sometimes track
        information about you. A number of the cookies we use only last for the
        duration of your web session and expire when you close your browser.
        Other cookies are used to remember you when you return to the Website
        and to continue for longer.
      </p>
      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Refunds and Returned Goods"
        />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          {" "}
          Refunds and Returned Goods
        </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Refunds and Returned Goods"
        />
      </div>
      <strong>
        By registering to sell on SONA3 Website, the seller agrees to deduct the
        shipping fees and SONA3 Website Commission fee from his payments and
        provide refunds to buyers who do not receive their products; or who
        receive the product late; or if the product does not match its
        specifications mentioned in the description. This is done as shown
        below:
      </strong>
      <p dir="ltr">
        <strong>
          {" "}
          (A) - No Delivery: If the Buyer does not receive a product, as
          described below, the Seller shall refund the amount paid to the Buyer:
        </strong>
        <ol className="sell_with_us">
          <li>
            If the buyer does not receive the requested product because the
            product was not sent.
          </li>
          <li>
            If the buyer does not receive this product because it was not sent
            to the address on SONA3 Website.
          </li>
          <li>
            If there is no evidence that the product was shipped to the
            requested address.
          </li>
        </ol>
      </p>
      <p dir="ltr">
        <strong>
          {" "}
          (B) - Late Delivery: If the Buyer provides proof that all of these
          conditions have been met, the Seller must refund the amounts paid to
          the Buyer:
        </strong>
        <ol className="sell_with_us">
          <li>If the product is requested for a specific date or occasion.</li>
          <li>If the product is considered useless after that date. </li>
          <li>
            If the seller does not ship the product according to the time it was
            processed or on the date agreed upon in the negotiations.
          </li>
        </ol>
      </p>
      <p dir="ltr">
        <strong>
          {" "}
          (C) - The product does not conform to the mentioned specifications: If
          the buyer proves that any of the following is true, the seller must
          refund the amount paid to the buyer:
        </strong>
        <ol className="sell_with_us">
          <li>
            If the product is completely different from the listing description
            or pictures.
          </li>
          <li>
            If the product received is of a different color, model, version, or
            size than what is shown in the picture or as described in the
            description.
          </li>
          <li>If the product has a different design or material.</li>
          <li>
            If the product is declared original and it turns out otherwise.
          </li>
          <li>
            If the seller failed to disclose the fact that a product was damaged
            or parts of it were missing.
          </li>
          <li>
            In the event that the product sustains damage and it is established
            that improper packaging caused the harm.
          </li>
          <li>
            If the quantity of the products ordered is different from what was
            received.
          </li>
          <li>
            If the product condition is described incorrectly. For example, the
            product description stated at the time of purchase that the product
            was “new” and it turned out to be used.
          </li>
        </ol>
      </p>
      <p dir="ltr">
        You are responsible for any non-conformity or defect in any of your
        Products or other products associated with your Products or any general
        or specific recall thereof. You shall notify us immediately as soon as
        you become aware of any general or specific recall of your products or
        other products related to your products.
      </p>

      <div className={style.header}>
        <img className={style.s_line} src={s_line} alt="Food and Drinks" />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          Food and Drinks
        </h1>
        <img className={style.s_line} src={s_line} alt="Food and Drinks" />
      </div>
      <p dir="ltr">
        The following special conditions apply to the sale of (food and
        beverage) products on SONA3 website.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        The seller must have his or her own business license and associated
        municipal permits to sell edible products on SONA3 website.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        The seller is responsible for preparing edible items in a clean and
        sanitary manner and in accordance with any applicable laws.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        The sale of hot or frozen foods that contain alcohol is not permitted.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        Food items must be clearly labeled with a list of ingredients used,
        production and expiration dates, and any special instructions or storage
        requirements in accordance with any applicable laws.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        Food items must be properly packed for delivery to avoid any
        contamination or spoilage. The price of perishable products, such as
        fresh baked goods, should include same-day delivery.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        Any member who does not adhere to these rules is prohibited from selling
        food and drinks on SONA3 website.
      </p>
      <p dir="ltr">
        <strong>-</strong>
        SONA3 Website does not sell any food items listed on the Website
        directly. We collect payments only as a sales channel for the seller,
        and therefore we do not guarantee any product listed on the Website or
        any transaction between the buyer and the seller. We have no control
        over and do not guarantee the quality, safety or legality of the
        products advertised, the truth or accuracy of user listings, the ability
        of sellers to sell products, the ability of buyers to pay for items, or
        whether a buyer or seller shall actually complete the entire purchase or
        sale of the product or whether they shall return the product. Therefore,
        “SONA3” is not responsible if the seller or buyer violates the same.
      </p>

      <div className={style.header}>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Health and Beauty Products"
        />
        <h1 style={{ fontFamily: "JosefinSans" }} className={style.h1}>
          {" "}
          Health and Beauty Products
        </h1>
        <img
          className={style.s_line}
          src={s_line}
          alt=" Health and Beauty Products"
        />
      </div>
      <p dir="ltr">
        Makeup, Cosmetics and Health products have a strict no refund / exchange
        policy once opened.
      </p>
      <p dir="ltr">
        Make-up, cosmetics and health products must comply with the UAE
        regulations on cosmetics and health products, and vendors producing
        these products must comply with these regulations and obtain specific
        permits and business licenses to sell these products where applicable
        and provide accreditations where required.{" "}
      </p>
      <p dir="ltr">
        <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
          sona3.ae
        </a>{" "}
        does not bear any responsibility for the health and beauty products sold
        on the Site and does not guarantee the use, effectiveness or safety of
        these products as advertised by the seller.
      </p>
      <strong> Value Added Tax</strong>
      <p dir="ltr">
        SONA3 is VAT registered and complies with the UAE VAT regulations. As
        such, SONA3 shall charge an additional 5% tax per item at checkout,
        which shall be visible to buyers who shall be able to see the cost of
        their item in addition to the 5% VAT calculated at checkout. The buyer
        shall pay the total price plus 5% VAT and SONA3 shall undertake to
        submit the VAT to the relevant authorities.
      </p>
      <p dir="ltr">
        Individual sellers on SONA3 Site are not required to register for VAT in
        order to sell on SONA3 Site.
      </p>
      <p dir="ltr">
        The Website Follow the laws, regulations, decisions and decrees
        regarding the application of taxes payable, which automatically entails
        amending the tax rate shown on SONA3 website without the need for
        special notification or warning.
      </p>
      <p dir="ltr">
        SONA3 website is not responsible and does not provide legal advice
        regarding the status of VAT and the obligations of sellers towards it or
        regarding sales made by sellers on sales channels other than SONA3
        website.
      </p>
    </div>
  );
}
