import style from './style.module.css'
import useApi from './../../components/Loading/LoadingApi';
import { useTranslation } from 'react-i18next';
import BreadCrumb from './../../components/Layout/BreadCrumb';
import { h_line, s_line } from '../../assets';
import { isMobileApp } from '../../utils/functions';
export default function PrivacyPolicyEn() {
useApi(600)
const{ i18n} = useTranslation()
  return (
    <div className={style.Content} style={{direction:i18n.language === 'en' ? "ltr" : "ltr", textAlign:i18n.language === 'en' ? "justify" : "justify"}}>
        {!isMobileApp &&<img className={style.image_h_line} src={h_line} alt='h_line' />}
        {!isMobileApp && <BreadCrumb/>}
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Privacy & Policy</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
        <div>
        <p dir="ltr">
            SONA ETRANSACTION COMPLEX - L.L.C - O.P.C Team welcomes you and expresses gratitude for your trust in them. It emphasizes its commitment to safeguarding user information and privacy rights in accordance with the Privacy Policy and confidentiality practices followed by SONA ETRANSACTION COMPLEX - L.L.C - O.P.C.        </p>
        <p dir="ltr">
            Therefore, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C. clarifies that the Privacy Policy and confidentiality of information, which will govern the handling of your personal information, are as follows:
        </p>

        <p dir="ltr">
        This Privacy Policy establishes the basis on which any personal data, including but not limited to payment details and other information collected from you or from other sources or provided to us ("Information"), regarding your personal data will be handled. Access to the website SONA3.ae and its use, and/or the "SONA3" mobile application (referred to collectively as the "Platform") and the services and applications (referred to collectively as the "Services"). We understand the importance you attach to this information, and we are committed to protecting and respecting your privacy. Please read the following carefully to understand our practices regarding your information. By using Our Services, you agree to the handling of your information in accordance with this Privacy Policy.
        </p>

        <p dir="ltr">
        References in this Privacy Policy to "we" or "our" or "us" (or similar words) are references to the "SONA3" Platform owned by SONA ETRANSACTION COMPLEX - L.L.C - O.P.C. References to "user" or "you" (or similar words) are references to you as an individual or legal entity as appropriate.
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}> The Information Obtained by "SONA3" and Retained in its Databases </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
        We may collect and process the following information about you:
        </p>

        <p dir="ltr">
            <strong>A-</strong>
            Information provided through filling out forms on Our Platform, including information provided during registration to use Our Platform and other joint registration processes (such as logging in through social media accounts), subscribing to Our Services, or posting materials or requesting additional services.
        </p>

        <p dir="ltr">
           <strong>B‌-</strong>
           Information provided when entering a contest or promotional offer through Our Platform, or submitting reviews, testimonials, or comments on Our Platform.   
        </p>
        <p dir="ltr">
           <strong>C‌-</strong>
           Information provided to us, or collected from you, when reporting a problem on Our Platform.
        </p>
        <p dir="ltr">
           <strong>D‌-</strong>
           Correspondence records if you contact us.        
        </p>
        <p dir="ltr">
           <strong>E‌-</strong>
           General, aggregated, and non-personal demographic information.
        </p>
        <p dir="ltr">
           <strong>F-</strong>
          If you download or use our mobile application, we may access details about your location and mobile device, including your device's unique identifier.
        </p>
        <p dir="ltr">
           <strong>G-</strong>
          Transaction details you carry out through Our Platform and fulfillment of your requests.
        </p>
        <p dir="ltr">
           <strong>H-</strong>
           Your computer device details, including but not limited to your IP address, operating system, and browser type, as well as information about your general internet usage (e.g., through technologies storing or accessing information on your device, such as cookies, tracking, pixels, web beacons, etc., collectively referred to as "cookies").
        </p>
        <p dir="ltr">
           <strong>I-</strong>
           Any other information we deem necessary to enhance your experience on the Platform.
        </p>

      
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Maintaining the Confidentiality of Information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
            <strong>A‌-</strong> 
            Naturally, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C seeks to retain this information in a manner that preserves user privacy. SONA ETRANSACTION COMPLEX - L.L.C - O.P.C only retains this information for the purpose of improving the quality of the e-commerce Platform and facilitating interaction between SONA ETRANSACTION COMPLEX - L.L.C - O.P.C and the user.        </p>
        <p dir="ltr">
            <strong>B-</strong> 
            As a general rule, all of this information is only accessible to those responsible for the "SONA3" Platform, and they will not disclose or broadcast it to third parties.        </p>

        <p dir="ltr">
            <strong>C-</strong> 
            As SONA ETRANSACTION COMPLEX - L.L.C - O.P.C seeks to safeguard the safety of users, if SONA ETRANSACTION COMPLEX - L.L.C - O.P.C notices any irregular or illegal activity performed by the user, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C may, after consulting with their legal counsel, report it to the appropriate authorities.        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Security and Confidentiality of the Store's Information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
   <div>
        <p dir="ltr">
            <strong>A‌- </strong>
            SONA ETRANSACTION COMPLEX - L.L.C - O.P.C strives to maintain the confidentiality of user information and Privacy Policy and will not violate the provisions of these rules and policies. However, due to the inability to guarantee this 100% over the internet, the team at SONA ETRANSACTION COMPLEX - L.L.C - O.P.C would like to emphasize the following:        </p>

        <p dir="ltr">
            <strong>B‌- </strong> 
            SONA ETRANSACTION COMPLEX - L.L.C - O.P.C aims to safeguard all user information and ensure that no one accesses it in a manner contrary to the applicable policy at SONA ETRANSACTION COMPLEX - L.L.C - O.P.C.        </p>
        <p dir="ltr">
            <strong>C‌- </strong>
            SONA ETRANSACTION COMPLEX - L.L.C - O.P.C seeks to protect this information through “specific servers” protected by electronic protection systems, either “specific software or programming” systems.        </p>

        <p dir="ltr">
            <strong>D‌- </strong>
            However, because the internet cannot be guaranteed 100% against breaches or viruses on electronic protection systems and the security walls applied in the "SONA3" Platform, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C advises users to keep their information confidential and not to disclose any information they deem highly important. This is a precautionary measure taken by SONA ETRANSACTION COMPLEX - L.L.C - O.P.C while guiding and advising users.        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Rules and Conditions for Using the “SONA3” Platform</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
        All obligations of SONA ETRANSACTION COMPLEX - L.L.C - O.P.C, as well as all obligations of the users, and all rights arising in the relationship between the user or consumer and SONA ETRANSACTION COMPLEX - L.L.C - O.P.C, are available "on the same page", where these rules constitute the "Privacy and Information Confidentiality Policy" derived from the agreement between the user and SONA ETRANSACTION COMPLEX - L.L.C - O.P.C regarding the regulatory and legal relationship between them. The Privacy and Information Confidentiality Policy has been established to ensure the credibility and trust that SONA ETRANSACTION COMPLEX - L.L.C - O.P.C is keen to provide to users.
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>How we will use your information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
            <strong>We may use the information we retain about you in the following ways:</strong>
        </p>

        <p dir="ltr">
            <strong>A-</strong>
            To provide you with information, products, or services you request from us or that we feel may interest you, where you have consented to be contacted for such purposes.        </p>

        <p dir="ltr">
            <strong>B-</strong> 
            To provide you with location-based services, such as advertisements, search results, and other personalized content.
        </p>

        <p dir="ltr">
            <strong>C‌- </strong> 
            To fulfill our obligations arising from any contracts entered into between you and another entity using Our Platform or between you and us.
        </p>

        <p dir="ltr">
            <strong>D- </strong> 
            To improve Our Services and provide a better and more customized service to you.
        </p>

        <p dir="ltr">
            <strong>E- </strong> 
            To ensure the content from Our Platform is presented in the most effective manner for you and for the device you use to access Our Platform.
        </p>

        <p dir="ltr">
            <strong>F‌- </strong>
            To notify you of changes to Our Services.
        </p>

        <p dir="ltr">
            <strong>G- </strong>
            For any other reason we consider necessary to enhance your experience on the Platform.
        </p>

        <p dir="ltr">
            <strong>H- </strong> 
            To manage and administer our incentive programs and fulfill your requests for incentives, and/or to allow you to participate in lotteries and notify you if you are a winner in the lottery.
        </p>

        <p dir="ltr">
        All details of credit/debit cards and personal identification information will not be stored, sold, shared, rented, or leased to any third party. We will not pass on any credit/debit card details to third parties. We take appropriate steps to ensure the privacy and security of data, including through various hardware and software methodologies. However, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C cannot guarantee the security of any information disclosed online. We are not responsible for the Privacy Policies of websites linked to ours. If you provide any information to these third parties, different rules regarding the collection and use of your personal information may apply. You should contact these entities directly if you have any questions about their use of the information they collect. Website policies, terms, and conditions may be changed or updated from time to time to meet requirements and standards. Therefore, we encourage customers to visit these sections frequently to stay informed about changes to the site. Modifications will be effective on the day they are posted and are binding and must be complied with.    
        </p>
       
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>To whom we May Disclose your Information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
            <strong>
            The information related to our customers is an important part of our business. We share your information only as described below and with companies that follow at least precautionary practices like those outlined in this Privacy Policy:
            </strong>
        </p>

        <p dir="ltr">
            <strong>A- </strong> 
            Other Businesses: To provide you with Our Services, we may deal with our affiliates and/or non-affiliated service providers (such as logistics companies used to deliver products to you, marketing companies, and payment processors to process online transactions, etc.). We may involve other companies in your transactions, which may store your information in a digital wallet to make your use of Our Services more efficient.        </p>

        <p dir="ltr">
            <strong>B‌- </strong> 
            Marketing and Promotions: We may also use your information to provide you with information about goods and services that may interest you and enhance your experience with the basic system, service messages, new features, improvements, special offers, and events that may interest you. We may contact you through various channels, including, but not limited to, email messages, push notifications, web notifications, mail, phone, in-app messages, and newsletters.        </p>
        <p dir="ltr">
            <strong>C‌- </strong> 
            Protecting Our Platform and Others: We may disclose account and other information when we believe disclosure is appropriate to comply with the law and law enforcement investigations or to protect the rights, property, or safety of our users or others. This includes exchanging information with other companies and organizations for various reasons, such as fraud protection and credit risk reduction.        </p>
        <p dir="ltr">
            <strong>D‌- </strong> 
            Please note that Our Platform may contain, from time to time, links to and from the websites of our partner networks, advertisers, and affiliates. If you follow a link to any of these websites, please note that these websites have their own Privacy Policies, and we do not accept any responsibility or liability for these policies. Please check these policies before submitting any personal data or other information to these websites, and therefore SONA ETRANSACTION COMPLEX - L.L.C - O.P.C is not responsible for the user providing any third party outside it with its personal data or any other information.       
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>Accounts and Registration Obligations</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
            <strong>Upon applying to join "SONA3" Platform as a user, you agree to disclose specific information and choose a username and a secret password to be used when accessing SONA3 Platform services. By doing so, you agree to the following:</strong>
        </p>

        <p dir="ltr">
            <strong>A‌)</strong>
           You are responsible for maintaining the confidentiality of your account information and password, and you agree to notify SONA ETRANSACTION COMPLEX - L.L.C - O.P.C immediately of any unauthorized use of your SONA3 account information or any other breach of security.
        </p>

        <p dir="ltr">
            <strong>B)</strong> 
           Under no circumstances will SONA ETRANSACTION COMPLEX - L.L.C - O.P.C be liable for any loss or damage suffered directly or indirectly, materially or morally, as a result of the disclosure of your username or login password.
        </p>

        <p dir="ltr">
            <strong>C)</strong> 
            You commit to using your SONA3 account or membership yourself, as you are fully responsible for it. If someone else uses it, it indicates that you have authorized them to use the store on your behalf and for your account.
        </p>

        <p dir="ltr">
            <strong>D‌)</strong>
            You agree to use SONA3 Platform seriously and credibly.
        </p>

        <p dir="ltr">
           <strong>E‌)</strong> 
           You agree to disclose true, accurate, current, complete, and legal information about yourself as required during registration with SONA ETRANSACTION COMPLEX - L.L.C - O.P.C and to update your information as needed in case of any changes or updates.
        </p>

        <p dir="ltr">
            <strong>F)</strong>
            SONA ETRANSACTION COMPLEX - L.L.C - O.P.C is committed to treating your personal information and contact details with complete confidentiality.
        </p>

        <p dir="ltr">
            <strong>G‌)</strong>
            If it is found that you have disclosed untrue, inaccurate, outdated, incomplete, or illegal information, or violated the terms of use agreement, SONA ETRANSACTION COMPLEX - L.L.C - O.P.C has the right to suspend, freeze, or cancel your membership and account in the store and Platform, without causing harm to other rights of SONA ETRANSACTION COMPLEX - L.L.C - O.P.C and its legitimate means of recovering its rights and protecting other users.
        </p>
        <strong>
        Failure to comply with any of the above may result in the management of SONA ETRANSACTION COMPLEX - L.L.C - O.P.C suspending or canceling your membership or blocking you from accessing the services of SONA ETRANSACTION COMPLEX - L.L.C - O.P.C again.
        </strong>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>How we Store your Information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
           We will store your information for as long as necessary to fulfill the purposes outlined in this Privacy Policy or as permitted by law. Your information may be transferred, stored, processed, and used by our affiliates and/or non-affiliated service providers in one or more countries outside your original country. Your payment details may be transferred to our affiliates and stored with them for, among other things, processing your payment details and providing support services to you.
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>What Security Measures We Have in Place</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
        We maintain commercially reasonable technical, administrative, and physical safeguards to ensure the secure handling of your information in accordance with this Privacy Policy and to protect against unauthorized access, alteration, disclosure, or destruction of it. For example, we may use encryption technology to secure your information during transit to Our Platform, as well as external firewalls and host-based firewall technology to prevent network-level attacks. Only authorized employees, contractors, and agents who need to know your information to perform their services are allowed access to this information.
        </p>
    </div>

    <div>
        <p>
        It is important for you to protect yourself from unauthorized access to your password and to your devices used to access Our Services. You are responsible for maintaining the confidentiality of your password. For example, make sure to log out when finished using a shared device.
        </p>
    </div>

    <div>
        <p dir="ltr">
        Unfortunately, transferring information over the internet is not completely secure. While we will do our utmost to protect your information, we cannot guarantee the security of information transmitted to Our Platform, and any transmission is at your own risk.
        </p>
    </div>
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>How You Can Access and Modify your Information</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
        <strong>You can access a wide range of information about your account and interactions with the Platform for the purpose of viewing and, in some cases, updating it.</strong>
        </p>
    </div>

    <div>
        <p>
            <strong>Examples of the information you can easily access on the Platform include:</strong>
        </p>
    </div>

    <div>
        <ul dir="ltr">
            <li><strong>A.</strong> Updated information about recent orders.</li>
            <li><strong>B.</strong> Personal identification information (including name, email, password, contact details, and personal advertising preferences).</li>
            <li><strong>C.</strong> Payment settings (including credit card information).</li>
            <li><strong>D.</strong> Email notification settings.</li>
        </ul>
    </div>

    <div>
        <p dir="ltr">
            <strong>You can unsubscribe from receiving future marketing communications from us at any time by adjusting your customer communication preferences through the unsubscribe link in email correspondence. For marketing through your mobile app, you will need to adjust notification settings in the general section on your mobile device.</strong>
        </p>
    </div>

    <div>
        <p>
            <strong>Our system will also place cookies when you log in to Our Platform to ensure you have an enjoyable user experience and the ability to benefit from all aspects of the Platform. You can disable cookies by changing settings in your browser. If you disable cookies, it may affect how Our Platform works, and you may not be able to access or use certain areas or features fully. For example, performance cookies collect information about how you use the site, such as the pages you visit most often, allowing us to provide you with targeted and relevant options that enhance your site experience.</strong>
        </p>
    </div>

    <div>
        <p dir="ltr">
            <strong>We may retain a copy of your information for compliance purposes. When you update your information, we may retain a copy of the previous version in our records.</strong>
        </p>
    </div>  
        <div className={style.header} >
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
            <h1 style={{fontFamily:'JosefinSans'}} className={style.h1}>What If We Were to Change Our Privacy Policy</h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPolicy" />
        </div>
    <div>
        <p dir="ltr">
            <strong>Our business undergoes continuous changes, so our Privacy Policy may need to be adjusted accordingly. We will publish the current version of this Privacy Policy on the website, and it will remain effective from the date of publication on the website or as determined by us as the effective date.</strong>
        </p>
    </div>

    <div>
        <p>
            <strong>We may periodically send reminder messages via email about our notifications and terms, but you should regularly check our website for the latest changes, and these messages do not violate your privacy.</strong>
        </p>
    </div>

    <div>
        <p dir="ltr">
            <strong>You should regularly check the Privacy Policy. Your continued use of the website after any changes constitutes your acceptance of this modified Privacy Policy.</strong>
        </p>
    </div>

    </div>
  )
}
