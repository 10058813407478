import  i18n  from 'i18next';
import {jwtDecode} from 'jwt-decode';

export const convertToArabicNumeral = (number) => {
    const arabicNumerals = {
        '0': '٠',
        '1': '١',
        '2': '٢',
        '3': '٣',
        '4': '٤',
        '5': '٥',
        '6': '٦',
        '7': '٧',
        '8': '٨',
        '9': '٩',
    };

    const lang = i18n.language;

    if (lang === 'ar') {
        return number.toString().split('').map(digit => arabicNumerals[digit]).join('');
    } else {
        return number.toString();
    }
};
export const Name = (language )=>{
    if(language==="en"){
        return `nameEn`
    }
    else{
        return `nameAr`
    }
  }
export const Description = (language )=>{
    if(language==="en"){
        return `descriptionEn`
    }
    else{
        return `descriptionAr`
    }
  }

  // ==delete any key in object has a undefined value==
export const paramsValidate = (params) => {
    if (params) {
      for (const [key, value] of Object.entries(params)) {
        if (value === undefined || value === '' || value === 'undefined') {
          delete params[key];
        }
        console.log(`${key}: ${value}`);
      }
    }
    console.log(params);
    return params;
  }
  
  export const getTokenData = () => {
    const token = localStorage.getItem('sona3_info_token');
    
    if (token) {
      try {
        const decoded = jwtDecode(token);
        return decoded;
      } catch (error) {
        console.error('Failed to decode token:', error);
        return null;
      }
    } else {
      return null;
    }
  };


    const queryParams = new URLSearchParams(window.location.search);
    export const isMobileApp = queryParams.get("platform" ) === 'mob'
 