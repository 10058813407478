import style from "./style.module.css";
import useApi from "../../components/Loading/LoadingApi";
import { useTranslation } from "react-i18next";
import BreadCrumb from "../../components/Layout/BreadCrumb";
import { h_line, s_line } from "../../assets";
import { isMobileApp } from "../../utils/functions";

export default function TermsConditionsAr() {
  useApi(600);
  const { t, i18n } = useTranslation();

  return (
    <>
      {!isMobileApp &&<img className={style.image_h_line} src={h_line} alt="h_line" />}

      <div
        className={style.Content}
        style={{
          direction: i18n.language === "en" ? "rtl" : "rtl",
          textAlign: i18n.language === "en" ? "justify" : "justify",
        }}
      >
       {!isMobileApp && <BreadCrumb />}
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            {t("Terms & Conditions")}
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            يُقصد بشروط الأحكام والشروط التي يتم بموجبها توريد وتسليم المشتريات
            إليك كمشترٍ على موقع{" "}
            <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
              sona3.ae
            </a>{" "}
            أو على تطبيق الهاتف المتحرك الخاص بنا (المشار إليهما مجتمعين
            بـ"الموقع") الذي تمتلكه (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و)
            في دولة الإمارات العربية المتحدة بموجب ترخيص رقم CN-4899615 لدى
            دائرة التنمية الاقتصادية بأبوظبي.
          </p>
        </div>

        <div>
          <p dir="rtl">
            تسري هذه الشروط والأحكام، التي تسمى فيما يلي (الشروط)، على ما تقوم
            (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) ويشار إليها بـ (صناع مجمع
            للتعامل الإلكتروني) من بيع وتوريد لجميع المنتجات والأجهزة وعلى ما
            تؤديه من جميع الخدمات للعميل.
          </p>
        </div>

        <div>
          <p dir="rtl">
            هذه الشروط والأحكام العامة ("الشروط") التي يتم إبرامها بينك وبين
            (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و).
          </p>
        </div>

        <div>
          <p dir="rtl">
            فيما يخص استخدامك لهذا الموقع، ودخولك اليه، واستخدامك لخدمات
            الموبايل الخاصة بـ (صناع مجمع للتعامل الإلكتروني) ، وخدمات وحدة
            الطلب الهاتفي والرد الصوتي لدى (صناع مجمع للتعامل الإلكتروني)،
            والتطبيقات البرمجية الخاصة بموقع (صناع مجمع للتعامل الإلكتروني التي
            تم تنزيلها على سطح المكتب الخاص بك، وعناوين البريد الإلكتروني الخاصة
            بموقع (صناع مجمع للتعامل الإلكتروني (، والمواقع والخدمات الأخرى التي
            تم نشر هذه الشروط عليها أو الإشارة إليها ("خدمات صناع مجمع للتعامل
            الإلكتروني") والوعود والالتزامات المنصوص عليها هنا، التي بدورك تعتزم
            الالتزام بها قانونًا، توافق أنت و (صناع مجمع للتعامل الإلكتروني (على
            ما يلي:
          </p>
        </div>

        <div>
          <p dir="rtl">
            يخضع وصولك لخدمات (صناع مجمع للتعامل الإلكتروني (واستخدامها لهذه
            الشروط، والتي تتضمن بيان سياسة الخصوصية والمنشورات أو السياسات
            الأخرى المنفصلة، بالإضافة إلى أي تعديلات تضيفها شركة (صناع مجمع
            للتعامل الإلكتروني (وبالإضافة الى جميع القوانين واللوائح المعمول
            بها.
          </p>
        </div>

        <div>
          <p dir="rtl">
            إذا كانت أي من خدمات صُناع قد حددت شروط وأحكام استخدام أو بيان
            خصوصية أو سياسة أخرى منفصلة ("شروط منفصلة")، فإن هذه الشروط المنفصلة
            التي ممكن أن يتم تعديلها من وقت لآخر يجب أن تُطبّق في أي اجراء يتعلق
            باستخدامك لتلك الخدمات المنصوصة من قبل صُناع. وفي حال تواجد أي تعارض
            مباشر بين الشروط المنفصلة وهذه الشروط، فإن الشروط المنفصلة تحكُم.
          </p>
        </div>

        <div>
          <p dir="rtl">
            من خلال استخدامك لخدمات صُناع ، أنت توافق على الالتزام بهذه الشروط
            وبأي سياسات أخرى قابلة للتطبيق. إذا كنت لا ترغب في الالتزام بهذه
            الشروط أو السياسات، فلا تستخدم خدمات موقع صُناع.
          </p>
        </div>

        <div>
          <p dir="rtl">
            توفر خدمات صُناع معلومات متعلقة بمختلف المنتجات والخدمات، بالإضافة
            الى فرصة الحصول على معلومات إضافية بخصوص تلك المنتجات والخدمات وفرصة
            شرائها. هذه الشروط والمعلومات التي تقدمها خدمات موقع صُناع لا تتجاوز
            بأي حال من الأحوال شروط وأحكام الشراء لأي منتج أو خدمة باستثناء ما
            هو محدد هنا. وفي حال وجود أي قسم داخل الموقع أو أي ميزة يقدمها موقع
            وخدمات صُناع يتضمّن بنود وشروط محددة فيما يتعلق باستخدامها ("شروط
            محددة")، فإن هذه الشروط المحددة تُضاف إلى هذه الشروط ويتم تطبيقها.
            وفي حال تواجد أي تعارض مباشر بين هذه الشروط والشروط المحددة، فإن
            الشروط المحددة تحكُم.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            مقدمة
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            موقع صُناع عبارة عن منصة إلكترونية بنيت بأيدي إماراتية، تم تصميمها
            لتكون بيتا لكل صانع ومبدع في مختلف المجالات. تم تصميمه بشغف ليتميز
            بتقنيات وتصميم فريد يتيح للمشتري الحصول على كل ما يبحث عنه بسهولة
            ويسر وأيضا يتيح للصناع تحميل منتجاتهم وخدماتهم بسهولة وعرضها بشكل
            جذاب ومميز.
          </p>
        </div>

        <div>
          <p dir="rtl">
            تتيح منصة "صُناع" للمبدعين والصانعين فرصة لتحويل أفكارهم الى حقيقة
            من خلال تقديم وسائل مبتكرة وفعالة لتسويق منتجاتهم والوصول الى جمهور
            أوسع مع تطبيق "صُناع" أصبح بإمكانك الانضمام الى مجتمع إبداعي.
          </p>
        </div>

        <div>
          <p dir="rtl">
            من المهم أن تقرأ وتفهم الاتفاقية أدناه. يعني استخدامك لهذا الموقع
            الإلكتروني قبولك لهذه الاتفاقية وأي تغييرات يتم إجراؤها بعد بدء
            الاستخدام.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            التغييرات في الشروط
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            يحق لموقع صُناع في أي وقت وبدون إشعار مسبق، بناءً على تقديره الخاص،
            مراجعة هذه الشروط أو فرض شروط وأحكام جديدة فيما يتعلق بالوصول إلى أو
            استخدام خدمات موقع صُناع. تسري هذه المراجعات والإضافات فورًا على
            الإشعار بذلك، والتي يمكن تقديمها بأي وسيلة، بما في ذلك على سبيل
            المثال، لا الحصر، نشر الشروط والأحكام المعدلة أو الإضافية على خدمات
            صُناع.
          </p>
        </div>

        <div>
          <p dir="rtl">
            أنت مسؤول عن مراجعة هذه الشروط بشكل دوري، لأي تعديل على هذه الشروط
            قد يؤثر على حقوقك أو التزاماتك بموجب هذه الاتفاقية.
          </p>
        </div>

        <div>
          <p dir="rtl">
            أنت توافق على أنك سوف تُعتبر على علم بأي تعديل من قبل صُناع لهذه
            الشروط. أي وصول أو استخدام لخدمات صُناع من قبلك بعد التعديلات أو
            الإضافات على هذه الشروط سوف يتم اعتباره موافقتك على هذه التعديلات أو
            الإضافات.
          </p>
        </div>

        <div>
          <p dir="rtl">
            أنت توافق على الامتثال لجميع القوانين والأنظمة والمراسيم المعمول بها
            فيما يخص استخدامك لخدمات موقع صُناع وشرائك للمنتجات أو الخدمات من
            خلال تلك القوانين لن يكون أي تعديل على هذه الشروط من قبل أي طرف آخر
            غير صُناع صالحًا أو قابلًا للتنفيذ ضد صُناع ما لم يتم الاتفاق عليه
            صراحة من قبل صُناع في كتابة موقعة من قبل مسؤول مخوّل حسب الأصول من
            صُناع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            إغلاق الحساب
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>

        <div>
          <p dir="rtl">
            تسري هذه الشروط حتى يتم إنهاؤها من قبل صُناع. قد تقوم صُناع بإنهاء
            هذه الشروط دون سابق إنذار وفي أي وقت فيما يتعلق بأي من خدمات موقع
            صُناع.
          </p>
        </div>

        <div>
          <p dir="rtl">
            في حالة الإنهاء، لن يكون الوصول إلى خدمات صُناع مُصرحًا لك، أما
            القيود المفروضة عليك فيما يتعلق بالمحتوى، وإخلاء المسئولية،
            والتعويضات، وحدود الالتزامات المنصوص عليها في هذه الشروط سوف تُطبق.
          </p>
        </div>

        <div>
          <p dir="rtl">
            كما سيكون لدى صُناع الحق أيضًا في أي وقت وبدون أي اشعار مسبق بإنهاء
            بعض أو كل خدمات موقع صُناع أو أي ميزة أو جزء منها، أو أي منتجات أو
            خدمات يتم تقديمها من خلالهم، أو إنهاء حق أي شخص في الوصول إلى خدمات
            موقع صُناع أو استخدامها أو الوصول الى أي ميزة أو جزء منها
            واستخدامها.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            المحتوى
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            يشار إلى النصوص والصور والرسومات والشعارات والرسوم التوضيحية
            والأوصاف والبيانات وغيرها من المواد التي يقدمها موقع صُناع في أو من
            خلال خدمات موقع صُناع ، فضلا عن اختيار وتجميع وترتيب ذلك، بشكل جماعي
            باسم "المحتوى ".
          </p>
        </div>

        <div>
          <p dir="rtl">
            قد يتضمن المحتوى بعض الأخطاء الغير متعمدة أو الأخطاء المطبعية، أو
            ممكن أن يكون المحتوى قديمًا. قد يقوم موقع صُناع بتغيير أو حذف أو
            تحديث أي محتوى في أي وقت وبدون إشعار مسبق. يتم توفير المحتوى لأغراض
            إعلامية فقط ولا يُعتبر الموقع ملزماً بذلك بأي طريقة إلا بالقدر الذي
            يتم الإشارة إليه على وجه التحديد.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ما لم تتم الإشارة إلى خلاف ذلك، فإن جميع المحتويات محميّة بحقوق
            النشر وحقوق العلامات التجارية وحقوق علامات الخدمة وحقوق الملكية
            الأخرى والمملوكة كلها من قبل موقع صُناع أو من قبل أطراف ثالثة قامت
            بترخيص استخدامها لموقع صُناع. يجوز لك عرض المحتوى واستخدامه فقط
            لمعلوماتك الشخصية وللتسوق واجراء الطلبات في أو من خلال خدمات موقع
            صُناع ، وليس لأي غرض آخر، كما يجب عليك الاحتفاظ بجميع حقوق الطبع
            والنشر وإشعارات الملكية الأخرى.
          </p>
        </div>

        <div>
          <p dir="rtl">
            باستثناء ما هو منصوص عليه في ما تقدم، لا يمنح موقع صُناع لك أو لأي
            شخص آخر أي حق في استخدام أو إعادة إنتاج أو نسخ أو تعديل أو نقل أو
            عرض أو نشر أو بيع أو ترخيص أو إنشاء أعمال مشتقة، أو اجراء أداء علني
            أو توزيع بأية وسيلة، أو طريقة، أو عملية مهما كانت على الإطلاق، أي من
            المحتوى الموجود أو المتناقل عبر خدمات موقع صُناع ، المعروف حاليًا أو
            التي يتم تطويره حاليًا بما في ذلك على سبيل المثال لا الحصر، نقل أي
            محتوى أو تنزيله أو نسخه بأي محرك أقراص أو وسيط تخزين آخر.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ممنوع منعًا باتًا أي استخدام للمحتوى، باستثناء ما هو مسموح به
            تحديدًا في هذه الشروط أو كما هو مسموح به بصراحة في المحتوى أو في
            كتابة موقعة من موقع صُناع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            المواقع المرتبطة التي يشغلها طرف ثالث
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>

        <div>
          <p dir="rtl">
            لا تشكل الارتباطات بمواقع الإنترنت الأخرى التي تشغلها أطراف ثالثة،
            بما في ذلك بائعي موقع صُناع ، رعاية أو تأييد أو موافقة من موقع صُناع
            للمحتوى أو السياسات أو الممارسات الخاصة بهذه المواقع المرتبطة.
          </p>
        </div>

        <div>
          <p dir="rtl">
            لا يتم تشغيل المواقع المرتبطة أو التحكم فيها أو صيانتها من قبل موقع
            صُناع ، ولا يتحمل موقع صُناع المسؤولية عن توفر أو محتويات أو أمان أو
            سياسات أو ممارسات المواقع المرتبطة، بما في ذلك على سبيل المثال لا
            الحصر سياسات وممارسات الخصوصية.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يتم توفير روابط لمواقع أخرى لراحتك فقط، اما الوصول إليها فهو على
            مسؤوليتك الخاصة.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            معاملات البيع والشراء
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            من خلال استخدام خدمات موقع صُناع، سيقوم المشترون والبائعون بإنشاء
            عقود ملزمة قانونًا لبيع السلع والخدمات. يجب على كلا الطرفان
            بالالتزام بالترتيب الخاص ببيع السلع أو الخدمات.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يجب على المستخدمين الذين يقومون بإجراء معاملات شراء و / أو بيع أن
            يكونوا على دراية وأن يتحملوا مخاطر استخدام مواقع الويب واجراءات
            الدفع الالكتروني. يجب أن يكون المستخدمين على دراية بالمستخدمين
            الآخرين الذين من الممكن أن يعملوا تحت التظاهر الزائف، أو بنية ارتكاب
            الاحتيال.
          </p>
        </div>

        <div>
          <p dir="rtl">
            بينما تتخذ صُناع إجراءات احترازية لضمان هوية وصلاحية المستخدمين
            الذين يوقعون إلكترونيًا لاستخدام خدمة الموقع، لا يمكننا ولا نؤكد
            هوية جميع الأطراف الذين يستخدمون خدمة{" "}
            <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
              sona3.ae
            </a>{" "}
            نقترح على جميع المستخدمين توظيف الحس السليم ونشجعهم على الاتصال بـ
            صُناع للإبلاغ عن أي سلوك مشبوه أو اختلاس لخدمة الموقع.
          </p>
        </div>
        <div>
          <p dir="rtl">
            لا تتحكم صُناع في المحتوى المنشور من قبل المستخدمين. إذا عثرت على
            محتوى يقدمه مستخدمون آخرون وتعتبره مسيئًا أو ضارًا أو مهينًا، فيمكنك
            الاتصال بنا عبر{" "}
            <a
              href="mailto:info@sona3.ae"
              style={{ color: "inherit", textDecoration: "none" }}
            >
              info@sona3.ae
            </a>{" "}
            توفر صُناع مجتمعًا مفتوحًا وتحتضن حرية التعبير للمستخدمين على الموقع
            ضمن القيود المنصوص عليها في هذه الشروط والأحكام والقرارات التي
            يتخذها موظفي{" "}
            <a href="https://sona3.ae/" style={{ fontFamily: "JosefinSans" }}>
              sona3.ae
            </a>{" "}
            .
          </p>
        </div>

        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            الأسعار، الطلبات
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            سيقوم موقع صُناع بإضافة رسوم الشحن والمناولة وضريبة
            المبيعات/الاستخدام المطبقة فوق السعر الذي يدفعه المشتري.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يتم احتساب جميع رسوم الشحن لدينا على أساس الوزن الحجمي للمنتج. الوزن
            الحجمي للمنتج هو: العرض × الطول × الارتفاع بالسنتيمترات/5000 = الوزن
            الحجمي بالكيلوغرام.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يحتفظ موقع صُناع بالحق دون إشعار مسبق بوقف أو تغيير المواصفات
            والأسعار على المنتجات والخدمات المقدمة من خلال خدمات موقع صُناع دون
            تكبد أي التزام تجاهك. لا تعني أوصاف أو إشارات المنتجات أو الخدمات
            التي يقدمها موقع صُناع دعمًا لهذا المنتج أو الخدمة، ولا يُشكل ذلك أي
            ضمانًا من نوعه من موقع صُناع.
          </p>
        </div>

        <div>
          <p dir="rtl">
            لا يشكل إيصال تأكيد الطلب الخاص بك موافقة موقع صُناع على الطلب. قبل
            قبول الموقع للطلب، قد يتطلب الأمر التحقق من المعلومات والموافقة على
            الطلب.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يحتفظ موقع صُناع بالحق في أي وقت بعد استلام طلبك بقبول أو رفض طلبك،
            أو أي جزء منه، حتى بعد استلامك لرسالة تأكيد الطلب من موقع صُناع ،
            لأي سبب من الأسباب.
          </p>
        </div>

        <div>
          <p dir="rtl">
            يحتفظ موقع صُناع بالحق في الحد من كمية الطلب على أي منتج ورفض الخدمة
            لأي عميل دون إشعار مسبق.
          </p>
        </div>

        <div>
          <p dir="rtl">
            في حالة إدراج سعر غير صحيح لمنتجٍ أو خدمةٍ ما بسبب معلومات خاطئة من
            البائعين أو خطأ مطبعي، يحق لموقع صُناع رفض أو إلغاء الطلبات الموضوعة
            للمنتج أو الخدمة المُدرجة بالسعر غير الصحيح، بغض النظر عما إذا كان
            الطلب تم تأكيده.
          </p>
        </div>

        <div>
          <p dir="rtl">
            إن مخاطر الخسارة لجميع المنتجات التي تشتريها ويتم شحنها من قبل موقع
            صُناع تنتقل اليك عند تسليم موقع صُناع المنتج لشركة الشحن لتوصيله
            اليك.
          </p>
        </div>

        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            إلغاء الطلب
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            أ‌- يمكن إلغاء طلب العميل واسترداد المبلغ المدفوع في حالة حدوث ما
            يلي :
          </p>
          <ul dir="rtl">
            <li>
              1. إذا لم يقبل البائع الطلب، وإلا إذا قبل البائع الطلب، فإن سياسة
              الإلغاء غير صالحة.
            </li>
            <li>2. إذا قام البائع بإلغاء الطلب.</li>
            <li>3. في حالة حدوث أي مشكلة فنية مع التطبيق.</li>
            <li>
              4. <span style={{ fontFamily: "JosefinSans" }}>Sona3</span> لها
              الحق أن يقرر إلغاء طلب أي عميل إذا كان هناك شك في أن حساب العميل
              مزيف.
            </li>
          </ul>
        </div>

        <div>
          <p dir="rtl">
            ب‌- سيتم إرجاع المبالغ المستردة إلى طريقة الدفع التي استخدمها العميل
            في البداية.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ت‌- يمكنك إلغاء الطلب واسترداد كامل المبلغ حتى يتم قبول الطلب من قبل
            البائع. إذا كنت بحاجة إلى إلغاء الطلب بعد قبوله من قبل البائع، فيرجى
            التواصل معنا.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ث‌- <span style={{ fontFamily: "JosefinSans" }}>Sona3</span> لها
            الحق في عدم إصدار مبالغ مستردة كاملة أو جزئية إذا قمت بإلغاء الطلب
            بعد قبوله.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ج- لا يمكن للعملاء إلغاء الطلب إذا اختاروا خدمة الدفع (الدفع عند
            الاستلام) باستثناء العملاء المؤهلين لسياسة إرجاع البضائع وفقًا
            للشروط والأحكام الواردة في قسم الاسترداد والبضائع المرتجعة.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ح- <span style={{ fontFamily: "JosefinSans" }}>Sona3</span> لها الحق
            في إلغاء أي طلب، قبل القبول أو بعده، وسوف يتم إخطارك على الفور بأي
            إلغاء من هذا القبيل.
          </p>
        </div>
        <p dir="rtl">
          بالنسبة للعملاء المؤهلين لسياسة الإلغاء أو سياسة إرجاع السلع، فإن
          عملية استرداد الأموال المرتجعة تستغرق ما يصل إلى 14 إلى 21 يوم عمل.
          يرجى الاتصال بنا على (
          <a
            href="tel:+971600505006"
            style={{
              color: "inherit",
              textDecoration: "none",
              fontFamily: "sans-serif",
              unicodeBidi: "plaintext",
            }}
          >
            +971 60 050 5006
          </a>
          ).
        </p>

        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            توصيل طلبك
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            تكلفة التوصيل: تكون تكلفة توصيل المنتجات وفقًا للأسعار المذكورة على
            الموقع.
          </p>
          <p dir="rtl">تاريخ التوصيل: سيتم عرض هذه المعلومات لك على الموقع.</p>
        </div>

        <div>
          <p dir="rtl">
            التأخير في التوصيل: إذا تأخر توصيلنا للمنتج لأسباب خارجة عن إرادتنا
            فسوف نتصل بك بأسرع ما يمكن لإعلامك بذلك، وسوف نتخذ خطوات للتقليل من
            تبعات التأخير في التوصيل.
          </p>
          <p dir="rtl">
            إذا لم يكن هناك أي شخص في عنوانك لتسلم المنتج وتعذر توصيل المنتج
            بالبريد على صندوق بريدك فسوف نعلمك في حينه عن كيفية تسليم المنتج أو
            تسلمك إياه.
          </p>
          <p dir="rtl">
            إذا لم تتمكن من تسلم المنتج منا كما هو متفق عليه أو لم تتمكن من
            إعادة تحديد موعد آخر لتسليم المنتج بعد تعذر تسليمه لك في عنوانك فسوف
            نتصل بك للحصول على المزيد من التعليمات. سوف نلغي طلبيتك وفقًا لشروط
            البيع الماثلة عند تعذر اتصالنا بك أو ترتيبنا لموعد جديد للتسليم أو
            التسلم على الرغم من جهودنا المتواصلة في هذا الشأن.
          </p>
        </div>

        <div>
          <p dir="rtl">
            متطلبات اثبات الشخصية عند التسليم: يجوز لصناع حسب تقديرها الخاص،
            إجراء أية استفسارات ترى أنها ضرورية للتحقق من هويتك و / أو ملكيتك
            لأدواتك المالية عن طريق طلب تزويدك بمزيد من المعلومات أو الوثائق،
            بما في ذلك، على سبيل المثال لا الحصر، طلب أي نموذج لإثبات الشخصية
            و/أو بطاقة الائتمان. إذا لم تتمكن صناع من التحقق من مصادقية أية
            معلومات تقدمها، فلدى صناع الحق في رفض التسليم وإلغاء الطلب.
          </p>
        </div>

        <div>
          <p dir="rtl">
            التوصيل من خارج البلاد: قد يتم تسجيلك كمستورد في حال طلبك توصيل
            منتجات تكون من خارج البلاد. وفي هذه الحالة، يجب عليك التحقق من أن
            المنتجات المطلوبة مطابقة للقانون واللوائح، وعليك أيضًا الالتزام
            بسداد كافة الرسوم والجمارك المقررة على مشترياتك.
          </p>
        </div>

        <div>
          <p dir="rtl">
            ملكية المنتجات: تصبح المنتجات مملوكة منك بمجرد أن نوصلها إليك في
            عنوان التسليم وسدادك لقيمتها كاملة.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            خدمات الدفع والسداد في{" "}
            <span style={{ fontFamily: "JosefinSans" }}>Sona3</span>
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            يوفّر صناع عبر شركائه نظام الدفع والسداد في (صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و) فيمكن أن يتم عبر الإنترنت كليًا من خلال
            خيارات الدفع المتوفرة على منصة "صُناع" أو من خلال أي طريقة دفع
            يوفرها (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) من حين لآخر، على
            سبيل المثال:
          </p>
          <ul dir="rtl">
            <li>من خلال بطاقة ائتمانية أو بطاقة خصم</li>
            <li>من خلال محفظتك الإلكترونية</li>
            <li>نقدًا عن التسليم (مبلغ لا يتجاوز 2500 درهم إماراتي)</li>
          </ul>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            معلوماتك الشخصية ومعلومات تفاصيل العمليات
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            أ- يقر المستخدم بأنه يمنح (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و)
            حقاً غير محدود، وعالمي، ودائم وغير قابل للإلغاء، ومعفي من المصاريف،
            ومرخص باستخدام معلومات أو مواد شخصية أو غير ذلك مما وفرتها أو زودت
            بها منصة "صُناع" أو أعلنتها على المنصة من خلال انضمامك إليها أو
            استخدامك لها، وذلك عبر النماذج المخصصة للتواصل و التسجيل، أو عبر أية
            رسالة إلكترونية أو أي من قنوات الاتصال المتاحة بالمنصة وذلك بهدف
            تحقيق أي من المصالح التي تراها المنصة.
          </p>
          <p dir="rtl">
            ب- تخضع أحكام سرية المعلومات الخاصة بالمستهلكين لقواعد "سياسة
            الخصوصية وسرية المعلومات" الخاصة بـ (صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و) ، ولما ورد في هذه الاتفاقية من أحكام متعلّقة
            بسرية المعلومات.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            إخلاء المسؤولية وتحديد المسؤولية المتعلقة بموقع{" "}
            <span style={{ fontFamily: "JosefinSans" }}>Sona3</span> والمحتوى
            الموجود فيه
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            لا يقدم موقع صُناع أي ضمانات أو إقرارات على الإطلاق فيما يتعلق
            بخدمات الموقع أو أي موقع أو محتوى مرتبط به، بما في ذلك توافر أي من
            المواقع أو المحتوى أو المعلومات والمواد المتعلقة به أو دقتها أو
            اكتمالها أو ملائمتها في ذلك المحتوى، والمعلومات والمواد. كما لا يضمن
            لك موقع صُناع أن وصولك إلى أو استخدام أي من خدمات موقع صُناع أو أي
            موقع مرتبط سيكون دون انقطاع أو خالٍ من الأخطاء أو عمليات الحذف، أو
            أن يتم تصحيح العيوب، أو أن خدمات موقع صُناع أو أي موقع مرتبط سيكون
            خالٍ من الفيروسات أو المكونات الضارة الأخرى. دون تقييد لما سبق ذكره،
            يتم تقديم جميع المحتويات الموجودة على أو من خلال خدمات موقع صُناع
            للمستخدمين "كما هي"، دون أي ضمانات من أي نوع، سواء كانت صريحة أو
            ضمنية، بما في ذلك على سبيل المثال لا الحصر الضمانات الضمنية للرواج
            والصلاحية لغرض معين، أو عدم الانتهاك، أو الأمان، أو الدقة.
          </p>
          <p dir="rtl">
            إن حالة المحتوى التي أشرنا اليها بـ"كما هي" تُعتبر وصف لأي تعامل
            ينشأ عن خدمات صُناع أو نتيجة لذلك. يُرجى العلم أن بعض السلطات
            القضائية قد لا تسمح باستثناء الضمانات الضمنية، لذلك قد لا تنطبق بعض
            الاستثناءات المذكورة أعلاه عليك. تحقق من القوانين المحلية الخاصة بك
            عن أي قيود أو موانع فيما يتعلق باستثناء الضمانات الضمنية.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            التعويض
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            أنت توافق على الدفاع عن موقع صُناع وبائعي موقع صُناع ويشمل ذلك
            شركتنا الأم أو شركاتنا الفرعية أو التابعة أو العاملين لدينا أو لديهم
            من مديرين أو أعضاء مجالس إدارة أو موظفين أو وكلاء أو موردين أو
            مقاولين من الباطن أو المرخصين من وضد جميع المطالبات والخسائر
            والأضرار والمسؤوليات والتكاليف (بما في ذلك على سبيل المثال لا الحصر،
            أتعاب المحاماة وتكاليف المحكمة المعقولة، الناتجة عن أو التي تتعلق
            بانتهاك هذه الشروط أو وصولك إلى أو استخدام خدمات موقع صُناع. يسري
            التزام التعويض السابق في حالة إنهاء هذه الشروط وإنهاء تشغيل خدمات
            موقع صُناع أو أي منتج أو خدمة مقدمة إليك ناشئة عن أو تتعلق باستخدامك
            لخدمات موقع صُناع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            التقديمات
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>

        <div>
          <p dir="rtl">
            باستثناء ما هو منصوص عليه صراحة بخلاف ذلك أو في بيان الخصوصية
            المنشور على موقع صُناع المنفصل، أي اتصال أو مادة تقوم بتقديمها إلى
            موقع صُناع من خلال خدمات الموقع بالبريد الإلكتروني أو غير ذلك، بما
            في ذلك على سبيل المثال لا الحصر أي مسابقة أو مشاركة في قرعات، أو
            صور، أو ملفات فيديو أو ملفات صوتية أو مقالات أو أسئلة أو تعليقات أو
            اقتراحات أو أي أفكار للميزات أو التعديلات أو التحسينات أو المنتجات
            أو التقنيات أو المحتوى أو العروض أو الاعلانات الترويجية أو
            الاستراتيجيات أو أسماء ميزات المنتجات أو أي مستندات ذات صلة أو أعمال
            فنية أو رموز كمبيوتر أو مخططات بيانية أو اي مواد أخرى (يُشار اليها
            مجتمعة، "بالتقديمات") ، سيتم التعامل معها على أنها غير سرية وغير
            مسجلة الملكية ويمكن استخدامها من قبل موقع صُناع أو بائعي صُناع أو
            الشركات التابعة له أو الكيانات ذات الصلة لأي غرض، بما في ذلك على
            سبيل المثال لا الحصر التعديل أو الإفصاح أو الإرسال أو النشر أو البث
            أو الإعلان أو الدعاية أو لأغراض ترويجية في أي وسيلة اعلامية بدون إذن
            أو موافقة أو دفع أو أي اعتبار آخر، ما لم يحظر القانون ذلك. وعلاوة
            على ذلك، فإن بائعي صُناع وموقع صُناع والشركات التابعة للموقع
            والكيانات المرتبطة به يتمتعون بحرية استخدام أي ملفات واردة في أي
            اتصال ترسله إلى موقع صُناع من خلال خدمات موقع صُناع لأي غرض كان، بما
            في ذلك على سبيل المثال لا الحصر، تطوير أو تصنيع وتسويق المنتجات أو
            الخدمات باستخدام المعلومات الواردة في هذه التقديمات. موقع صُناع ليس
            ملزماً بمراجعة، أو دراسة، أو تنفيذ طلبات التقديم الخاص بك، أو اعادة
            أو إرسال أي جزء منها لك لأي سبب. باستخدامك لخدمات صُناع ، فإنك توافق
            على أن موقع صُناع قد يتواصل معك عن طريق الرسائل النصية القصيرة SMS
            أو غيرها من الوسائل الإلكترونية الموجودة على جهازك المحمول، وأن بعض
            المعلومات حول استخدامك لخدمات صُناع قد يتم توصيلها إلى موقع صُناع.
            في حالة تغيير رقم هاتفك المحمول أو إلغائه، فإنك توافق على الفور على
            تحديث معلومات حساب اشتراكك المحمول لضمان عدم إرسال الرسائل التي
            يعتزم موقع صُناع إرسالها إليك إلى شخص آخر يحصل على رقم هاتفك المحمول
            السابق. يختلف عدد التنبيهات التي ستتلقاها بناءً على اختياراتك وعدد
            المرات التي تقوم فيها بالطلب من موقع صُناع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            سياسة الخصوصية
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            باستثناء ما هو منصوص عليه صراحة بخلاف ذلك أو في بيان الخصوصية
            المنشور على موقع صُناع المنفصل، أي اتصال أو مادة تقوم بتقديمها إلى
            موقع صُناع من خلال خدمات الموقع بالبريد الإلكتروني أو غير ذلك، بما
            في ذلك على سبيل المثال لا الحصر أي مسابقة أو مشاركة في قرعات، أو
            صور، أو ملفات فيديو أو ملفات صوتية أو مقالات أو أسئلة أو تعليقات أو
            اقتراحات أو أي أفكار للميزات أو التعديلات أو التحسينات أو المنتجات
            أو التقنيات أو المحتوى أو العروض أو الاعلانات الترويجية أو
            الاستراتيجيات أو أسماء ميزات المنتجات أو أي مستندات ذات صلة أو أعمال
            فنية أو رموز كمبيوتر أو مخططات بيانية أو اي مواد أخرى (يُشار اليها
            مجتمعة، "بالتقديمات") ، سيتم التعامل معها على أنها غير سرية وغير
            مسجلة الملكية ويمكن استخدامها من قبل موقع صُناع أو بائعي صُناع أو
            الشركات التابعة له أو الكيانات ذات الصلة لأي غرض، بما في ذلك على
            سبيل المثال لا الحصر التعديل أو الإفصاح أو الإرسال أو النشر أو البث
            أو الإعلان أو الدعاية أو لأغراض ترويجية في أي وسيلة اعلامية بدون إذن
            أو موافقة أو دفع أو أي اعتبار آخر، ما لم يحظر القانون ذلك. وعلاوة
            على ذلك، فإن بائعي صُناع وموقع صُناع والشركات التابعة للموقع
            والكيانات المرتبطة به يتمتعون بحرية استخدام أي ملفات واردة في أي
            اتصال ترسله إلى موقع صُناع من خلال خدمات موقع صُناع لأي غرض كان، بما
            في ذلك على سبيل المثال لا الحصر، تطوير أو تصنيع وتسويق المنتجات أو
            الخدمات باستخدام المعلومات الواردة في هذه التقديمات. موقع صُناع ليس
            ملزماً بمراجعة، أو دراسة، أو تنفيذ طلبات التقديم الخاص بك، أو اعادة
            أو إرسال أي جزء منها لك لأي سبب. بإستخدامك لخدمات صُناع ، فإنك توافق
            على أن موقع صُناع قد يتواصل معك عن طريق الرسائل النصية القصيرة SMS
            أو غيرها من الوسائل الإلكترونية الموجودة على جهازك المحمول، وأن بعض
            المعلومات حول استخدامك لخدمات صُناع قد يتم توصيلها إلى موقع صُناع.
            في حالة تغيير رقم هاتفك المحمول أو إلغائه، فإنك توافق على الفور على
            تحديث معلومات حساب اشتراكك المحمول لضمان عدم إرسال الرسائل التي
            يعتزم موقع صُناع إرسالها إليك إلى شخص آخر يحصل على رقم هاتفك المحمول
            السابق. يختلف عدد التنبيهات التي ستتلقاها بناءً على اختياراتك وعدد
            المرات التي تقوم فيها بالطلب من موقع صُناع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            المبالغ المستردة والسلع المُرجعة
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            من خلال التسجيل للبيع على موقع صُناع ، يوافق البائع على استرداد
            المبالغ المدفوعة للمشترين الذين لا يتلقون منتجاتهم؛ أو الذين يتلقون
            المنتج متأخراً؛ أو في حالة عدم تطابق المنتج مع مواصفاته المذكورة في
            الوصف. ويتم ذلك كما هو موضح أدناه:
            <br></br>
            أ‌- لا توصيل: إذا لم يستلم المشتري منتج ما، كما هو موضح أدناه، يجب
            على البائع استرداد المبلغ المدفوع للمشتري:
            <ul>
              <li>
                في حال لم يتلقى المشتري المنتج المطلوب لأنه لم يتم إرسال المنتج.
              </li>
              <li>
                في حال لم يتلقى المشتري هذا المنتج لأنه لم يتم إرساله إلى
                العنوان الموجود على موقع صُناع.
              </li>
              <li>
                في حال عدم توفر أي دليل على شحن المنتج إلى العنوان المطلوب.
              </li>
            </ul>
            ب‌- التسليم المتأخر: إذا قدم المشتري دليلاً على استيفاء جميع هذه
            الشروط، يجب على البائع استرداد المبالغ المدفوعة للمشتري:
            <ul>
              <li>إذا تم طلب المنتج لتاريخ أو مناسبة محددة.</li>
              <li>يتم اعتبار المنتج عديم الفائدة بعد ذلك التاريخ.</li>
              <li>
                إذا لم يقم البائع بشحن المنتج حسب وقت معالجته أو في التاريخ
                المتفق عليه في المحادثات.
              </li>
            </ul>
            ت‌- عدم تطابق المنتج مع المواصفات المذكورة: إذا أثبت المشتري أن أي
            مما يلي صحيحًا، يجب على البائع استرداد المبلغ المدفوع للمشتري:
            <ul>
              <li>إذا كان المنتج مختلفًا تمامًا عن وصف القائمة أو الصور.</li>
              <li>
                إذا كان المنتج الذي تم تلقيه ذا لون أو طراز أو إصدار أو حجم
                مختلف عما هو معروض في الصورة أو كما هو موضح في الوصف.
              </li>
              <li>إذا كان المنتج ذا تصميم أو مادة مختلفة.</li>
              <li>إذا تم الإعلان عن المنتج أنه أصلي وتبين غير ذلك.</li>
              <li>
                فشل البائع في الكشف عن حقيقة تلف منتج ما أو فقدان أجزاء منه.
              </li>
              <li>
                في حالة تعرض المنتج للضرر وثبت أن التغليف غير المناسب هو الذي
                سبب الضرر.
              </li>
              <li>
                إذا كانت كمية المنتجات التي تم طلبها مختلفة عن ما تم استلامه.
              </li>
              <li>
                إذا كان وصف حالة المنتج غير صحيح. على سبيل المثال، ذُكر في وصف
                المنتج وقت الشراء أن المنتج "جديد" وتبين انه مستخدم.
              </li>
            </ul>
            أنت مسؤول عن أي عدم مطابقة أو خلل في أي من منتجاتك أو منتجاتك الأخرى
            المرتبطة بمنتجاتك أو أي استرجاع عام أو خاص لها. سوف تقوم بإعلامنا
            على الفور بمجرد أن تكون على علم بأي استرجاع عام أو خاص لمنتجاتك أو
            المنتجات الأخرى المتعلقة بمنتجاتك.
            <p>
              يجب على المشتري أن يخبرنا في حال وجود أحد الأسباب المذكورة أعلاه،
              بشرط أن يلتزم بإخطارنا خلال يومين من استلامه المنتج.
            </p>
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            الأطعمة والمشروبات
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            لا يقوم موقع صُناع ببيع أي مواد غذائية مدرجة على الموقع بشكل مباشر،
            نحن نقوم بتحصيل المدفوعات فقط كقناة بيع للبائع، وبالتالي فإننا لا
            نضمن أي منتج مدرج على الموقع أو أي معاملة بين المشتري والبائع. ليس
            لدينا أي سيطرة ولا نضمن جودة أو سلامة أو قانونية المنتجات المعلن
            عنها، أو صحة أو دقة قوائم المستخدمين، أو قدرة البائعين على بيع
            المنتجات، أو قدرة المشترين على دفع ثمن المواد، أو ما إذا كان المشتري
            او البائع سيكمل فعليًا عملية بيع أو شراء المنتج كاملة أو إذا كان
            سيعيد المنتج.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            الصحة والجمال
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            لدى منتجات الماكياج ومستحضرات التجميل والصحة سياسة عدم استرداد/تبديل
            صارمة بمجرد فتحها. لا يتحمل موقع صُناع أي مسؤولية عن منتجات الصحة
            والجمال التي تباع على الموقع ولا تضمن استخدام هذه المنتجات أو
            فعاليتها أو سلامتها كما هو معلن من قبل البائع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            حدود المسؤولية
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            (أ) إن مسؤولية (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) وشركاتها
            المنتسبة هي مسئولية منبثقة من مورد الخدمة أو السلعة وهو المسئول
            (مورد الخدمة أو السلعة) المسئولية الكاملة عنها حيث يقتصر طبيعة عمل
            صناع على تسويق المنتج أو الخدمة عبر منصتها فقط دون أدنى مسئولية على
            صناع عن أي مطالبات مهما كان نوعها الناشئة عن تقديم المنتجات أو
            الخدمات أو المتصلة بها إلى العميل أو على نحو آخر ناشئة عن هذه
            الاتفاقية أو متصلة بها
          </p>
          <p dir="rtl">
            (ب) لن تكون شركة (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) بأي حال
            من الأحوال مسؤولة عن أي أرباح أو مدخرات ضائعة وفقدان بيانات أو فقدان
            سمعة أو خسارة شهرة، أو أضرار غير مباشرة أو عقابية أو خاصة أو
            استتباعية، سواءً كانت تلك الأضرار قائمة على المسؤولية التقصيرية أو
            الضمان أو العقد أو لاء، أو حتى وإن كانت (صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و) قد أبلغت أو أنها كانت مدركة لإمكانية وقوع
            تلك الأضرار.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            الاتصالات الإلكترونية ووسائل التواصل الرسمية
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            أ- يوافق المستخدم على أن جميع الاتفاقيات والإعلانات والبيانات
            والاتصالات الأخرى التي تزود بها الكترونياً تقوم مقام مثيلاتها
            المكتوبة ، وهي حجة قائمة بذاتها ، في تلبية الاحتياجات النظامية
            والشرعية.
          </p>
          <p dir="rtl">
            ب- يوافق المستخدم على إمكانية التواصل معه وتبليغه بأي أحكام تخص هذه
            الاتفاقية أو تخص التعامل معه من خلال قيام إدارة (صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و) ببث رسائل عامة ترد إلى كافة المستخدمين أو
            إلى مستخدمين محددين لـ (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و).
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            السرية
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            يجب على العميل أن يحافظ على المعلومات الفنية والتجارية والمالية، بما
            فيها أي عرض وشروط تسعيرة وأي بيانات أخرى تم الإفصاح عنها للعميل من
            قبل (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) ، ولا يجوز لها
            الإفصاح عن تلك المعلومات لأي طرف ثالث أو استخدامها في أي غرض آخر سوى
            الغرض المتفق عليه بين الطرفين وفيما يتعلق بالعرض أو الاتفاقية أو
            كليهما.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            التنازل والاستمرارية
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            (أ) لا يجوز للعميل التنازل عن أي اتفاقية أو أي من حقوقه أو التزاماته
            بموجب هذه الاتفاقية دون موافقة مسبقة من(صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و).<br></br>
            (ب) يجوز لشركة (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) تفويض أو
            التنازل عن التزاماتها وحقوقها أو بيعها أو استبدالها كلياً أو جزئياً
            (بما في ذلك المبالغ المستحقة القبض) بموجب أي اتفاقية إلى أي من
            شركاتها المنتسبة أو أي طرف ثالث دون موافقة مسبقة من العميل، وفي حال
            كون تلك الموافقة مطلوبة بموجب القانون المعمول به، في هذه الحالة يجب
            على العميل دعم جهود (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) ، بما
            في ذلك تقديم المعلومات ذات الصلة، وتحرير الوثائق، والدفع لصالح
            الأطراف الثالثة حسب إخطار (صناع مجمع للتعامل
            الإلكتروني-ذ.م.م-ش.ش.و).
          </p>
        </div>
        <div>
          <p dir="rtl">
            التنازل عن أحد الشروط: لا يجوز أن يُفسر تنازلنا عن أي من أحكام شروط
            البيع هذه على أنه تنازل عن أي أحكام أخرى واردة فيها (سواء كانت
            أحكاماً مشابهة أم مختلفة)، ولا يجوز أن يُفسر التنازل عن أحد الأحكام
            على أنه تنازل دائم عنه، إلا إذا عبرنا عن ذلك صراحة وبصورة خطية.
          </p>
        </div>

        <div>
          <p dir="rtl">
            الاستمرارية: تظل كل الأحكام الواردة في شروط الاستخدام الماثلة،
            الصريحة منها أو الباقية المستمرة بطبيعتها، تظل سارية حتى عند تعليق
            أو انتهاء صلاحية عضويتك في الموقع.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            الالتزام بالقوانين, مكافحة الرشوة
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            (أ) ينبغي على العميل في جميع الأوقات الالتزام واتخاذ الإجراءات
            الضرورية في حدود المعقول ليضمن بأن شركاؤه في الأعمال يلتزمون
            بالقوانين والأنظمة المحلية والدولية المعمول بها، بما في ذلك لوائح
            مكافحة الرشوة والفساد ولوائح التصدير. وبناءً عليه يجب أن يسير العميل
            أعماله بنزاهة وعدم تورطه في أعمال رشوة أو فساد.
          </p>
        </div>

        <div>
          <p dir="rtl">
            (ب) في حالة تلقي (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) أي
            مؤشرات عن أي إخلال بالالتزامات يتعين على العميل التعاون معها وتزويد
            (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) بكافة المعلومات المطلوبة
            للسماح لـ (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) بالتحقق من تلك
            المؤشرات، ومن ثم اتخاذ الإجراءات المناسبة حيال ذلك الأمر.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            المسئولية القانونية والقانون الواجب التطبيق والمحكمة المختصة
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>
        <div>
          <p dir="rtl">
            (أ) تخضع جميع الاتفاقيات والعروض وهذه الشروط لحكم القوانين المعمول
            بها دون مراعاة لقواعد تضارب القوانين. أي دعوى أو إجراءات قانونية
            تنشأ عن أي اتفاقية أو عرض أو عن هذه الشروط والتي لم تتم تسويتها من
            خلال المشورة بحسن نية بموجب إخطار خطي مدته (30) ثلاثين يوماً من
            تاريخ النزاع من قبل أي من الطرفين يتم رفعه بشكل حصري إلى المحكمة
            المختصة بدولة الإمارات العربية المتحدة شريطة أن يُسمح في جميع
            الأحوال لشركة (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و ) برفع أي
            دعوى أو اتخاذ إجراءات قانونية ضد العميل أمام أي محكمة مختصة أخرى.
          </p>
        </div>

        <div>
          <p dir="rtl">
            (ب) لا يوجد في شروط الاستخدام هذه ما من شأنه أن يحد أو يعف مسؤولية
            أي طرف عن: <br></br>
            1- التحايل، بما في ذلك التدليس الذي يرتكبه هذا الطرف. <br></br>
            2- الوفاة أو الإصابة الشخصية الناجمة عن الإهمال من جانب هذا الطرف.{" "}
            <br></br>
            3- أي مسؤولية أخرى لا يجوز حدها أو الإعفاء منها بموجب القانون
            المعمول به.
          </p>
        </div>

        <div>
          <p dir="rtl">
            (ج) وفقاً للبنود المشار إليها لن نكون مسؤولين نحن أو شركتنا الأم أو
            شركاتنا الفرعية أو التابعة أو العاملون لدينا أو لديهم من مديرين أو
            أعضاء مجالس إدارة أو موظفين أو وكلاء أو موردين أو مقاولين من الباطن
            أو المرخصين، تأسيساً على حكم محكمة أو مطالبة ناشئة عن العقد أو على
            القانون المدني أو بسبب التقصير أو الإخلال بواجبات قانونية أو ناشئة
            عن شروط الاستخدام الماثلة أو متعلقة بها، عن خسارة أرباح أو بيانات أو
            معلومات أو أي أضرار لاحقة أو عرضية أو غير مباشرة أو خاصة، حتى لو تم
            إخطارنا نحن أو شركاتنا التابعة أو مديرينا أو أعضاء مجالس إداراتنا أو
            وكلائنا أو موظفينا أو المرخصين أو مقاولي الباطن أو الموردين بإمكانية
            بحدوث مثل تلك الأضرار.
          </p>
        </div>

        <div>
          <p dir="rtl">
            (ح) بالإضافة إلى ذلك، أنه وحسب ما يسمح به القانون الساري، فإننا
            –ويشمل ذلك شركتنا الأم أو شركاتنا الفرعية أو التابعة أو العاملين
            لدينا أو لديهم من مديرين أو أعضاء مجالس إدارة أو موظفين أو وكلاء أو
            موردين أو مقاولين من الباطن أو المرخصين- لن نكون مسؤولين قانوناً،
            كما أنك تؤكد موافقتك هنا على عدم مسؤوليتنا عن أي أضرار أو خسائر قد
            تنشأ عما يلي سواء مباشرة أو بطرق غير مباشرة: <br></br>
            1- التوصيل المتأخر للمنتجات أو الإخفاق في توصيل جزء منها إذا أخفقت
            أنت نفسك في تزويدنا بالمعلومات التي نحتاجها خلال وقت معقول منذ تاريخ
            طلبنا ذلك أو إذا أخفقت في سداد القيمة. <br></br>
            2- الأضرار التي قد تنتج عن الإصلاحات غير المصرح بها للمنتجات.{" "}
            <br></br>
            3- فقدان أي بيانات مخزنة أو تم حفظها في المنتجات التي تم إصلاحها أو
            استبدالها.<br></br>
            4- اعتمادك تماماً على المحتوى أو أي معلومات أخرى يوفرها الموقع وتخص
            المنتج الذي أصدرت له أمر الشراء. <br></br>
            5- استخدامك أو عدم قدرتك على استخدام المنتج الذي طلبته.<br></br>
            6- تأخر أو تعطل الموقع أو تأخر أو تعطل خدماتنا.<br></br>
            7- الفيروسات أو البرمجيات الخبيثة الإلكترونية الناتجة عن استخدام
            المنتج الذي طلبته.<br></br>
            8- الضرر الواقع على جهازك الخاص نتيجة استخدام المنتج الذي طلبته.
            <br></br>
            {/* 9- فقدانك فرصة عمل أو عدم قدرتك على ممارسة أعمالك أو ما شابه بسبب عدم قدرتنا على توصيل المنتج الذي طلبته في الوقت المحدد.<br></br> */}
            9- فقدانك فرصة عمل أو عدم قدرتك على ممارسة أعمالك أو ما شابه بسبب
            عدم قدرتنا على توصيل المنتج الذي طلبته في الوقت المحدد. <br></br>
            طبقا للبند( ب), اذا تبين أن البنود( ج) و( ح )ليست مطبقة أو غير نافذه
            لاسبب من الأسباب فعندئذ تنحصر المسؤولية القانونية الكاملة لنا أو
            لشركتنا الأم أو شركاتنا الفرعية أو التابعة أو العاملين لدينا أو
            لديهم من مديرين أو أعضاء مجالس إدارة أو موظفين أو وكلاء أو موردين أو
            مقاولين من الباطن أو المرخصين تجاهك سواء بناءً علي حكم محكمة أو
            مطالبة ناشئة عن العقد أو بسبب التقصير أو الإخلال بواجبات قانونية أو
            ناشئة عن شروط الاستخدام هذه الماثلة أو تتعلق بها, في الحدود التالية
            : مبلغ ثلاثمائة (300) درهم اماراتي.
          </p>
        </div>

        <div>
          <p dir="rtl">
            (خ) توافق بموجب هذا على تعويضنا وإخلاء مسؤوليتنا نحن أو شركتنا الأم
            أو شركاتنا الفرعية أو التابعة أو العاملين لدينا أو لديهم من مديرين
            أو أعضاء مجالس إدارة أو موظفين أو وكلاء أو موردين أو مقاولين من
            الباطن أو المرخصين، عن الخسائر أو الأذى أو الأضرار أو المصروفات (بما
            في ذلك الرسوم القانونية وأتعاب المحامين) الناشئة عن أو لها علاقة بـ:
            <br></br>
            1- أي مطالبات أو طلبات يقدمها أي طرف ثالث تكون ناشئة عن استخدامك
            للموقع ولخدماتنا.<br></br>
            2- مخالفتك أياً من شروط وأحكام هذا الاتفاق بما فيها ودون قصر أي
            ضمانات أو إقرارات أو تعهدات. <br></br>
            3- أو أي مخالفة للقوانين المعمول بها.
          </p>
        </div>
        <div className={style.header}>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
          <h1 style={{ fontFamily: "GE_SS_bold" }} className={style.h1}>
            أحكام عامة
          </h1>
          <img className={style.s_line} src={s_line} alt="PrivacyPulicy" />
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>
              القانون المعمول به:
            </strong>{" "}
            تخضع شروط الاستخدام وأي حقوق والتزامات غير تعاقدية ناشئة عن شروط
            الاستخدام المذكورة أو ذات صلة بها للقوانين المطبقة في إمارة أبوظبي
            وتُفسَّر وفقاً لهذه القوانين.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>
              تسوية المنازعات:
            </strong>{" "}
            <br></br>- إذا كنت غير راضٍ عن أي المنتجات التي اشتريتها عبر الموقع،
            يمكنك التواصل معنا عبر البريد الإلكتروني، أو عبر مواقع التواصل
            الاجتماعي الخاصة بنا، أو المحادثة المباشرة على الموقع، أو الاتصال
            بمركز الاتصال الخاص بنا على رقم الهاتف{" "}
            <a
              href="tel:+971600505006"
              style={{
                color: "inherit",
                textDecoration: "none",
                fontFamily: "sans-serif",
              }}
            >
              <span style={{ fontFamily: "JosefinSans" }}>(٩٧١٦٠٠٥٠٥٠٠٦)+</span>
            </a>{" "}
            (في الإمارات العربية المتحدة) <br></br>- إذا لم تستطع التوصل إلى حل
            خلال خمسة وأربعين (45) يوماً من إخطارك للبائع بمشكلتك، يمكنك اللجوء
            إلى التحكيم من قبل محكم واحد. ويكون مركز التحكيم في مركز أبوظبي
            للتوفيق والتحكيم التجاري وتكون اللغة العربية هي لغة التحكيم.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>حقوق الغير:</strong> لا
            يحق لأي طرف غير أطراف هذه الاتفاقية وضع أيٍ من بنودها موضع التنفيذ.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>علاقة الأطراف:</strong>{" "}
            لا يجوز تفسير أيٍّ من المذكور في محتوى شروط الاستخدام الماثلة أو
            اعتباره سواء من أطرافها أو من قبل أي طرف ثالث على أنه مشاركة أو شركة
            مشتركة بين أطراف الاتفاق، حيث إنه من المفهوم أن أطراف الاتفاق دخلوا
            في علاقة تعاقد على أداء خدمة كل منهم مستقل عن الآخر.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>
              التأكيدات الإضافية:
            </strong>{" "}
            اتفق الأطراف على أن تعمل وتنفذ أو تتخذ الترتيبات لعمل وتنفيذ كل فعل
            مطلوب أو وثيقة أو أي شيء بشكل معقول كلٍّ في نطاق صلاحياته لتنفيذ
            وتفعيل شروط الاستخدام الماثلة إلى أقصى مدى لها بما في ذلك ودون قصر
            مساعدة بعضهما البعض في الامتثال للقانون المعمول به.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>التنازل:</strong> شروط
            الاستخدام هذه ملزمة لضمان مصلحة أطرافها وخلفائهم والمتنازل لهم
            المسموح لهم، وتوافق على عدم التنازل عن أو نقل صلاحية تلك الشروط أو
            أي من الحقوق أو الالتزامات التي تخصك بموجب شروط الاستخدام الماثلة
            سواء مباشرة أو بطريقة غير مباشرة دون الحصول على موافقة مبدئية خطية
            من قبلنا على ألا نمتنع من جانبنا عن إصدار الموافقة دون إبداء سبب
            معقول.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>
              التعديلات والتغييرات:
            </strong>{" "}
            لا يمكن تغيير أو تنويع أو تعديل أو استكمال شروط الاستخدام هذه بأي
            شكل من قبلك وحدك، ونحتفظ بحقنا في تعديل وتنويع واستكمال شروط
            الاستخدام في أي وقت ومن حين لآخر. سوف نقوم أيضاً بنشر النسخة الحالية
            من شروط الاستخدام على الموقع، وسيكون كل تغيير سارياً فور النشر على
            الموقع أو عند تاريخ تعيينا للتاريخ كتاريخ سريان (حسبما ينطبق). إن
            استمرارك في استخدام الموقع وخدماتنا بعد إحداث أي تغيير يعد موافقة
            منك على الالتزام بما حدث من تغييرات والعمل بما ورد في الشروط بعد
            تعديلها وتغييرها.
          </p>
        </div>

        <div>
          <p dir="rtl">
            <strong style={{ fontFamily: "GE_SS_bold" }}>
              قابلية الفصل بين البنود:
            </strong>{" "}
            إذا قضت أي محكمة اختصاص بعدم سريان أي من أحكام شروط الاستخدام هذه أو
            عدم شرعيتها أو عدم قابليتها للإنفاذ، يتم فوراً إلغاء هذا البند من
            تلك الشروط الماثلة وتستمر باقي الشروط والأحكام قائمة سارية نافذة
            طالما ظل الجوهر القانوني والاقتصادي للصفقات التي تمت تحت شروطها
            قائماً دون أي تأثير معاكس على أطرافها.
          </p>
        </div>

        <div>
          <p dir="rtl">
            * إن بطلان أي حكم من أحكام هذه الشروط أو الاتفاقية وعدم قابليتها
            للإنفاذ لا يؤثران على سريان وقابلية أي حكم آخر من أحكامها للإنفاذ
            والتي تظل جميعها سارية المفعول ونافذة تماماً. في حال التوصل إلى حالة
            بطلان أو عدم إنفاذ، على الطرفين أن يسعيا لاستبدال الأحكام الباطلة أو
            غير القابلة للإنفاذ بحكم/ أحكام سارية المفعول التي تتوافق بشكل وثيق
            مع مقاصد الأحكام التي تم إبطالها. على العميل بموجب إخطار معقول
            مساعدة (صناع مجمع للتعامل الإلكتروني-ذ.م.م-ش.ش.و) في التحقق من
            التزامه بالاتفاقية.
          </p>
        </div>
      </div>
    </>
  );
}
